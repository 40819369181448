import http from '@/services/http'
import { isEmpty } from '@/utils/lodash'

const state = {
  clientJourney: {}
}
// getters
const getters = {
  hasClientJourney: state => !isEmpty(state.clientJourney)
}
// actions
const actions = {
  async getClientJourney ({ commit }, workOrderId) {
    try {
      const { data } = await http.get(`work-orders/${workOrderId}/client-journey`)
      commit('SET_CLIENT_JOURNEY', data)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async updateClientJourney ({ commit }, { workOrderId, payload }) {
    try {
      const { data } = await http.patch(`work-orders/${workOrderId}/client-journey`, payload)
      commit('SET_CLIENT_JOURNEY', data)
    } catch (e) {
      return Promise.reject(e)
    }
  }
}
// mutations
const mutations = {
  CLEAR_CLIENT_JOURNEY (state) {
    state.clientJourney = {}
  },
  SET_CLIENT_JOURNEY (state, value) {
    state.clientJourney = { ...state.clientJourney, ...value }
  }
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
