import http from '@/services/http'
import { find } from '@/utils/lodash'

const state = {
  managers: []
}

// getters
const getters = {
  getManagerById: state => id => find(state.managers, manager => manager.id === id),
  managerTitle: (state, getters) => id => getters.getManagerById(id)
}

// actions
const actions = {
  async all ({ commit }) {
    const { data: { data } } = await http.get('managers')
    commit('SET_MANAGERS', data)
  }
}

// mutations
const mutations = {
  SET_MANAGERS (state, value) {
    state.managers = value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
