import http from '@/services/http'

// getters
const getters = { }

// actions
const actions = {
  async createSection (_, { policyTabId, ...params }) {
    const { data } = await http.post(`policy-tabs/${policyTabId}/sections`, { policy_tab_id: policyTabId, ...params })
    return data.data
  },
  async updateSection (_, { policyTabId, id, ...params }) {
    const { data } = await http.patch(`policy-tabs/${policyTabId}/sections/${id}`, { policy_tab_id: policyTabId, ...params })
    return data.data
  },
  async destroySection (_, { policyTabId, id }) {
    const { data } = await http.delete(`policy-tabs/${policyTabId}/sections/${id}`)
    return data.data
  }
}

// mutations
const mutations = {}

export default {
  namespaced: true,
  getters,
  actions,
  mutations
}
