import http from '@/services/http'
import { isEmpty } from '@/utils/lodash'

const state = {
  areas: [],
  areaTypes: [
    'Country',
    'District',
    'Locality',
    'Neighborhood',
    'Place',
    'Region'
  ],
  currentArea: {},
  mapCount: 1,
  mapboxKey: process.env.VUE_APP_MAPBOX_ACCESS_TOKEN
}

// getters
const getters = {
  hasAreas: state => !isEmpty(state.areas)
}

// actions
const actions = {
  async listAreas ({ commit }) {
    const { data } = await http.get('areas?per_page=all')
    commit('SET_AREAS', data.data)
  },
  async getArea ({ commit }, id) {
    const { data } = await http.get(`areas/${id}`)
    commit('SET_CURRENT_AREA', data.data)
  },
  async createArea ({ commit }, area) {
    const { data } = await http.post('areas', area)
    return data.data
  },
  async updateArea ({ commit }, { id, diff }) {
    await http.patch(`areas/${id}`, diff)
    return true
  },
  async deleteArea (context, id) {
    await http.delete(`areas/${id}`)
    return true
  }
}

// mutations
const mutations = {
  SET_AREAS (state, value) {
    state.areas = value
  },
  CLEAR_CURRENT_AREA (state) {
    state.currentArea = {}
  },
  SET_CURRENT_AREA (state, value) {
    state.currentArea = value
  },
  INCREMENT_MAP_COUNT (state, value) {
    state.mapCount++
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
