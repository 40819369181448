import Vue from 'vue'
import {
  Alert,
  AnimatedButton,
  Avatar,
  Badge,
  Button,
  Card,
  Checkbox,
  Dropdown,
  Help,
  Icon,
  Input,
  InputGroup,
  Label,
  Modal,
  Notification,
  Pagination,
  Switch,
  Tab,
  Table,
  Tabs,
  Textarea
} from '@relocity/ui'

import Form from '@/components/ui/forms/form.vue'
import SwitchStatus from '@/components/ui/switch-status.vue'
import AutoComplete from '@/components/ui/autocomplete'

Vue.use(Notification)

Vue.component('r-alert', Alert)
Vue.component('r-animated-button', AnimatedButton)
Vue.component('r-avatar', Avatar)
Vue.component('r-badge', Badge)
Vue.component('r-button', Button)
Vue.component('r-card', Card)
Vue.component('r-checkbox', Checkbox)
Vue.component('r-dropdown', Dropdown)
Vue.component('r-form', Form)
Vue.component('r-help', Help)
Vue.component('r-icon', Icon)
Vue.component('r-input', Input)
Vue.component('r-input-group', InputGroup)
Vue.component('r-label', Label)
Vue.component('r-modal', Modal)
Vue.component('r-pagination', Pagination)
Vue.component('r-select', () => import(/* webpackChunkName: "ui-select" */'./ui/select.js'))
Vue.component('r-phone-input', () => import(/* webpackChunkName: "ui-phone-input" */'./ui/phone-input.vue'))
Vue.component('r-date-time-picker', () => import(/* webpackChunkName: "ui-date-time-picker" */'./ui/date-picker.vue'))
Vue.component('r-switch', Switch)
Vue.component('r-switch-status', SwitchStatus)
Vue.component('r-tab', Tab)
Vue.component('r-table', Table)
Vue.component('r-tabs', Tabs)
Vue.component('r-textarea', Textarea)
// Vue Form Generator Custom Fields
// Register the new field as a global components.
// Registered name must validate this RegExp: (field)[A-Z][A-z]*.
Vue.component('fieldCustomFormDatePicker', () => import(/* webpackChunkName: "vue-form-generator-custom-fields-date-picker" */'./ui/vue-form-generator-custom-fields/date-picker.vue'))

/**
 * Due to a bug in the way this component loads it's state when loaded in async mode
 * we have to load it in in sync, this will trigger an update to the
 * display data property when it's destroyed and re-created
 */
Vue.component('r-autocomplete', AutoComplete)

export {
  Alert,
  AnimatedButton,
  AutoComplete,
  Avatar,
  Badge,
  Button,
  Card,
  Checkbox,
  Dropdown,
  Form,
  Help,
  Icon,
  Input,
  InputGroup,
  Label,
  Modal,
  Notification,
  Pagination,
  Switch,
  SwitchStatus,
  Tab,
  Table,
  Tabs,
  Textarea
}
