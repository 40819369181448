export const ADMIN = 'Admin'
export const SUPER_ADMIN = 'Super Admin'
export const DISPATCHER = 'Dispatcher'
export const AGENT = 'Agent'
export const SUPER_AGENT = 'Super Agent'
export const AREA_OPS_MANAGER = 'Area Ops Manager'
export const HOST = 'Host'
export const CLIENT = 'Client'
export const SERVICE_PROVIDER = 'Service Provider'
export const DEFAULT = 'Default'

export const ROLES = [
  SUPER_ADMIN,
  DEFAULT,
  CLIENT,
  ADMIN,
  SERVICE_PROVIDER,
  DISPATCHER,
  AREA_OPS_MANAGER,
  HOST,
  SUPER_AGENT,
  AGENT
]
