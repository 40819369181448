<template>
  <dashboard-layout>
    <router-view />
  </dashboard-layout>
</template>

<script>
import DashboardLayout from '@/layouts/dashboard.vue'
export default {
  components: {
    DashboardLayout
  }
}
</script>
