import http from '@/services/http'
const state = {
  prices: [],
  price: {}
}
// getters
const getters = {
}
// actions
const actions = {
  async createPrice ({ commit }, { productId, payload }) {
    try {
      const { data } = await http.post(`products/${productId}/prices`, payload)
      commit('SET_PRICE', data.data)
      return data.data
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async deletePrice ({ commit }, { productId, priceId }) {
    try {
      return await http.delete(`products/${productId}/prices/${priceId}`)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async getPrice ({ commit }, { productId, priceId }) {
    try {
      const { data } = await http.get(`products/${productId}/prices/${priceId}`)
      commit('SET_PRICE', data.data)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async listPrices ({ commit }, productId) {
    try {
      const { data } = await http.get(`products/${productId}/prices`)
      commit('SET_PRICES', data.data)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async restorePrice (c, { productId, priceId }) {
    await http.post(`products/${productId}/prices/${priceId}/restore`)
  },
  async updatePrice ({ commit }, { productId, priceId, payload }) {
    try {
      const { data } = await http.patch(`products/${productId}/prices/${priceId}`, payload)
      commit('SET_PRICE', data.data)
    } catch (e) {
      return Promise.reject(e)
    }
  }
}
// mutations
const mutations = {
  CLEAR_PRICE (state) {
    state.price = {}
  },
  SET_PRICES (state, value) {
    state.prices = value
  },
  SET_PRICE (state, value) {
    state.price = { ...state.price, ...value }
  }
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
