import Vue from 'vue'
import { sync } from 'vuex-router-sync'
import VueRouter from 'vue-router'
import routes from './routes.js'
import store from '../store'
import http from '../services/http'
import { page } from 'vue-analytics'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: __dirname,
  routes
})

sync(store, router)

router.beforeEach(async (to, from, next) => {
  if (store.state.layout.showMobileMenu) {
    store.commit('layout/HIDE_MOBILE_MENU')
  }
  let authUser = store.state.auth.authUser
  const savedRoute = JSON.parse(localStorage.getItem('savedRoute'))
  const returnRoute = to.name !== 'home' ? to : null
  switch (to.name) {
    case 'login':
      router.app.$auth.login()
      break
    case 'callback':
      next()
      break
    default:
      if (!router.app.$auth.isAuthenticated()) {
        // don't need to save the route if it's 'home'
        router.app.$auth.logout(JSON.stringify(returnRoute))
        router.app.$auth.login()
        break
      }
      if (authUser === null) {
        // direct navigation to a route doesn't have the auth user in state.
        const { data } = await http.get('users/profile')
        authUser = data.data
        store.commit('auth/SET_AUTH_USER', authUser)
      }
      // if user has application access
      if (!Vue.$can('application', 'read')) {
        Vue.notify('danger', `${authUser.role.name} role does not have access to this application`)
        store.commit('auth/CLEAR_AUTH_USER')
        router.app.$auth.logout(JSON.stringify(returnRoute))
        break
      }
      // if user can view page
      if (Vue.$can(to.name, 'read')) {
        if (savedRoute && to.name !== savedRoute.name && savedRoute.name !== 'home') {
          router.push(savedRoute)
          localStorage.removeItem('savedRoute')
          break
        }
        next()
        break
      }
      if (from.name !== 'home') {
        router.push({ name: 'home' })
      }
      break
  }
})

router.afterEach((to, from) => {
  page(to.path)
})

export default router
