import http from '@/services/http'
import { startsWith } from 'utils/lodash'

export async function addMedia (ownerType, ownerId, mediaType, payload) {
  try {
    return await http.post(`${ownerType}/${ownerId}/media/${mediaType}`, payload)
  } catch (e) {
    return Promise.reject(e)
  }
}

export async function deleteMedia (ownerType, ownerId, mediaType, mediaId) {
  try {
    return await http.delete(`${ownerType}/${ownerId}/media/${mediaType}/${mediaId}`)
  } catch (e) {
    return Promise.reject(e)
  }
}

const brightnessCache = {}
export function getImageBrightness (imageSrc, callback) {
  if (imageSrc === null || imageSrc === 'undefined') {
    const data = 0
    callback(data)
    return
  }

  if (imageSrc in brightnessCache) {
    callback(brightnessCache[imageSrc])
    return
  }

  const img = document.createElement('img')
  img.crossOrigin = 'Anonymous'
  img.src = imageSrc
  img.style.display = 'none'
  document.body.appendChild(img)

  let colorSum = 0

  img.onload = function () {
    // create canvas
    const canvas = document.createElement('canvas')
    canvas.width = this.width
    canvas.height = this.height

    const ctx = canvas.getContext('2d')
    ctx.drawImage(this, 0, 0)

    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height)
    const data = imageData.data
    let r, g, b, a, avg
    let pixelCount = 0

    for (let x = 0, len = data.length; x < len; x += 4) {
      r = data[x]
      g = data[x + 1]
      b = data[x + 2]
      a = data[x + 3]

      if (a < 128) {
        continue
      }

      avg = Math.floor((r + g + b) / 3)
      colorSum += avg
      pixelCount++
    }

    const brightness = Math.floor(colorSum / pixelCount)
    brightnessCache[imageSrc] = brightness
    callback(brightness)
  }

  img.onerror = function () {
    const data = 0
    callback(data)
  }
}

export function getExtension (filename) {
  return filename.split('.').pop()
}

export function assetType (type) {
  if (startsWith(type, 'video')) {
    return 'videos'
  }

  if (startsWith(type, 'image')) {
    return 'photos'
  }

  return 'document'
}

export const defaultHero = 'https://cdn.relocity.com/assets/images/relocity-pattern-black.svg'
