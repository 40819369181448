import http from 'axios'
import USStates from '@/utils/us-states'
import { map, includes, split, orderBy, isEmpty, find } from '@/utils/lodash'

const state = {
  countries: []
}

// getters
const getters = {
  prefered: () => split(process.env.VUE_APP_PREFERRED_COUNTRIES, ','),
  states: () => map(USStates, (label, id) => ({ id, label })),
  countries: (state, getters) => map(state.countries, (label, id) => {
    return { id, label, rank: includes(getters.prefered, id) ? 2 : 0 }
  }),
  countriesList: (state) =>
    Object.entries(state.countries).map(([key, value]) => ({
      id: key,
      label: value
    })),
  getCountry: (state, getters) => id => find(getters.countriesList, { id }),
  getCountryById: state => id => find(state.countries, (value, key) => id === key),
  countryTitle: (state, getters) => id => getters.getCountryById(id),
  getState: state => id => find(USStates, (value, key) => id === key),
  stateTitle: (state, getters) => id => getters.getState(id),
  groupedCountries: (state, getters) => {
    const list = getters.countries

    if (isEmpty(list)) {
      return []
    }

    list.push({ id: -1, label: 'Prefered', rank: 3, $isDisabled: true })
    list.push({ id: -1, label: '--------------------', rank: 1, $isDisabled: true })

    return orderBy(list, ['rank', 'label'], ['desc', 'asc'])
  }
}

// actions
const actions = {
  async all ({ commit }) {
    const { data: { country } } = await http.get(process.env.VUE_APP_COUNTRIES_ENDPOINT)
    commit('SET_COUNTRIES_LIST', country)
  }
}

// mutations
const mutations = {
  SET_COUNTRIES_LIST (state, value) {
    state.countries = value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
