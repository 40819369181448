import http from '@/services/http'
import { defaultHero } from 'utils/media'

const state = {
  activeTab: 'service',
  currentProviderPage: null,
  defaultBackground: defaultHero,
  links: [],
  meta: {},
  serviceProviderPages: null
}

const getters = {}

const actions = {
  async list ({ commit }, { id, params }) {
    try {
      const { data } = await http.get(`service-providers/${id}/pages`, { params })
      commit('SET_SERVICE_PROVIDER_PAGES', data.data)
      commit('SET_SERVICE_PROVIDER_META', data.meta)
      commit('SET_SERVICE_PROVIDER_LINKS', data.links)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async get ({ commit }, { providerId, pageId }) {
    try {
      const { data } = await http.get(`service-providers/${providerId}/pages/${pageId}`)
      commit('SET_CURRENT_PROVIDER_PAGE', data.data)
      return data.data
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async create (c, payload) {
    try {
      return await http.post(`service-providers/${payload.id}/pages/`, payload.params)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async update ({ commit }, { providerId, pageId, diff }) {
    const { data } = await http.patch(`service-providers/${providerId}/pages/${pageId}`, diff)
    commit('UPDATE_CURRENT_PROVIDER_PAGE', data.data)
  },
  async delete (c, { providerId, pageId }) {
    try {
      return await http.delete(`service-providers/${providerId}/pages/${pageId}`)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async assignArea (c, { serviceProviderId, serviceProviderPageId, areaId }) {
    await http.post(`service-providers/${serviceProviderId}/pages/${serviceProviderPageId}/areas/${areaId}`)
  },
  async unassignArea (c, { serviceProviderId, serviceProviderPageId, areaId }) {
    await http.delete(`service-providers/${serviceProviderId}/pages/${serviceProviderPageId}/areas/${areaId}`)
  }
}

const mutations = {
  SET_ACTIVE_TAB (state, value) {
    state.activeTab = value
  },
  SET_CURRENT_PROVIDER_PAGE (state, value) {
    state.currentProviderPage = value
  },
  SET_SERVICE_PROVIDER_PAGES (state, value) {
    state.serviceProviderPages = value
  },
  CLEAR_SERVICE_PROVIDER_PAGES (state) {
    state.serviceProviderPages = null
  },
  SET_SERVICE_PROVIDER_META (state, value) {
    state.meta = value
  },
  SET_SERVICE_PROVIDER_LINKS (state, value) {
    state.links = value
  },
  UPDATE_CURRENT_PROVIDER_PAGE (state, value) {
    state.currentProviderPage = { ...state.currentProviderPage, ...value }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
