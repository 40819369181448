import http from '@/services/http'

const state = {
  fieldTypes: []
}

// getters
const getters = { }

// actions
const actions = {
  async createSection (c, { goalId, ...params }) {
    const { data } = await http.post(`goals/${goalId}/sections`, params)
    return data.data
  },
  async deleteSection (c, { goalId, sectionId }) {
    const { data } = await http.delete(`goals/${goalId}/sections/${sectionId}`)
    return data.data
  },
  async sortSections (c, { goalId, ...params }) {
    const { data } = await http.post(`goals/${goalId}/sections/order`, params)
    return data.data
  },
  async updateSection ({ commit }, { goalId, sectionId, payload }) {
    const { data } = await http.patch(`goals/${goalId}/sections/${sectionId}`, payload)
    return data.data
  }
}

// mutations
const mutations = {
  SET_FIELD_TYPES (state, value) {
    state.fieldTypes = value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
