import http from '@/services/http'

const state = {
  channels: []
}

// getters
const getters = {
  hasChannels: state => state.channels.length > 0,
  emailChannel: state => state.channels.find(({ channel }) => channel === 'email'),
  firstChannel: state => state.channels[0],
  hasEmailChannel: (_, getters) => !!getters.emailChannel,
  template: (_, getters) => {
    if (getters.hasEmailChannel) {
      return getters.emailChannel.template
    }
    return getters.firstChannel?.template || null
  },
  nonEmailChannelsList: state => state.channels.filter(({ channel }) => channel !== 'email'),
  defaultEmailMessage: (_, getters) => {
    return getters.emailChannel?.template?.default_message || null
  }
}

// actions
const actions = {
  async send (c, { workOrderId, payload }) {
    const { data } = await http.post(
            `work-orders/${workOrderId}/guides/send`,
            payload
    )
    return data.data
  },
  async resend (c, { workOrderId, payload }) {
    const { data } = await http.post(
            `work-orders/${workOrderId}/guides/resend`,
            payload
    )
    return data.data
  },
  async cancel (c, { workOrderId, deliverableId }) {
    const { data } = await http.delete(
        `work-orders/${workOrderId}/guides/cancel/${deliverableId}`
    )
    return data.data
  },
  async bulk (c, { workOrderId, payload }) {
    const { data } = await http.post(
            `work-orders/${workOrderId}/guides/send/bulk`,
            payload
    )
    return data.data
  },
  async channels ({ commit }, workOrderId) {
    const { data } = await http.get(`work-orders/${workOrderId}/guides/channels`)

    commit('SET_CHANNELS', data.data)

    return data.data
  }
}

// mutations
const mutations = {
  SET_CHANNELS (state, channels) {
    state.channels = channels
  },
  CLEAR_CHANNELS (state) {
    state.channels = []
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
