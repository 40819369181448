import Vue from 'vue'
import axios from 'axios'
import store from '@/store'
import socket from '@/services/echo'
import { logout } from 'utils/helpers'
import { setupCache } from 'axios-cache-interceptor'

const http = axios.create({
  baseURL: process.env.VUE_APP_API_BASE + process.env.VUE_APP_API_VERSION,
  headers: {
    'X-Requested-With': 'XMLHttpRequest'
  }
})

// Add a response interceptor
http.interceptors.response.use(
  function (response) {
    store.dispatch('layout/decrementProcessing')
    return response
  },
  function (error) {
    const statusCode = error.response.status
    switch (statusCode) {
      case 400:
        if (error.response.data.errors) {
          const messages = error.response.data.errors.map((error) => {
            return error.detail
          })
          Vue.notify({
            variant: 'danger',
            html: messages.join('<br/>')
          })
        } else {
          Vue.notify(
            'danger',
            error.response.data ?? '400 Bad Request'
          )
        }
        break
      case 401:
        Vue.notify('danger', '401 Unauthorized')
        logout()
        break
      case 403:
        Vue.notify('danger', '403 Forbidden')
        break
      case 422:
        Vue.notify('danger', error.response.data.errors[0].detail)
        store.dispatch('layout/validationErrors', error.response.data.errors)
        break
      default:
        if (error.response.data.errors) {
          const messages = error.response.data.errors.map((error) => {
            return error.detail
          })
          Vue.notify({
            variant: 'danger',
            html: messages.join('<br/>')
          })
        } else {
          Vue.notify('danger', error)
        }
        break
    }
    store.dispatch('layout/processingError')
    store.dispatch('layout/decrementProcessing')
    return Promise.reject(error)
  }
)

setupCache(http)

http.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem('relocity_access_token')
    const socketId = socket.socketId()

    if (token != null) {
      config.headers.Authorization = `Bearer ${token}`
    }

    if (socketId != null) {
      config.headers['X-Socket-ID'] = socketId
    }

    store.dispatch('layout/incrementProcessing')
    return config
  },
  function (error) {
    store.dispatch('layout/decrementProcessing')
    return Promise.reject(error)
  }
)

export default http
