import http from '@/services/http'
import { filter } from '@/utils/lodash'

const state = {
  conditionProperties: []
}

// getters
const getters = {}

// actions
const actions = {
  async getGroupedConditions ({ commit }, { announcementId }) {
    const { data } = await http.get(`company-announcements/${announcementId}/conditionals`)
    return data.data
  },
  async getConditionProperties ({ commit }) {
    const { data } = await http.get('company-announcement-conditional-properties')
    commit('SET_CONDITION_PROPERTIES', filter(data.data, function (property) { return property.property !== 'Client Company' }))
  },
  async createCondition ({ commit }, { announcementId, params }) {
    const { data } = await http.post(`company-announcements/${announcementId}/conditionals`, params)
    return data.data
  },
  async updateCondition ({ commit }, { announcementId, conditionId, params }) {
    const { data } = await http.patch(`company-announcements/${announcementId}/conditionals/${conditionId}`, params)
    return data.data
  },
  async deleteCondition ({ commit }, { announcementId, conditionId }) {
    const { data } = await http.delete(`company-announcements/${announcementId}/conditionals/${conditionId}`)
    return data.data
  },
  async saveConditionOrder (c, { announcementId, params }) {
    const { data } = await http.post(`company-announcements/${announcementId}/conditionals/order`, params)
    return data
  }
}

// mutations
const mutations = {
  SET_CONDITION_PROPERTIES (state, value) {
    state.conditionProperties = value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
