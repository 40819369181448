import http from '@/services/http'

const state = {
  currentAnnouncement: null,
  timeZones: null
}

// getters
const getters = { }

// actions
const actions = {
  async assignArea (c, { companyId, announcementId, areaId }) {
    await http.post(`companies/${companyId}/announcements/${announcementId}/areas/${areaId}`)
  },
  async unassignArea (c, { companyId, announcementId, areaId }) {
    await http.delete(`companies/${companyId}/announcements/${announcementId}/areas/${areaId}`)
  },
  async createAnnouncement ({ dispatch }, companyId) {
    const { data } = await http.post(`companies/${companyId}/announcements`)
    return data.data
  },
  async getAnnouncement ({ commit }, { companyId, announcementId }) {
    const { data } = await http.get(`companies/${companyId}/announcements/${announcementId}`)
    commit('SET_CURRENT_ANNOUNCEMENT', data.data)
  },
  async updateAnnouncement ({ commit }, { companyId, announcementId, payload }) {
    const { data } = await http.patch(`companies/${companyId}/announcements/${announcementId}`, payload)
    commit('SET_CURRENT_ANNOUNCEMENT', data.data)
  },
  async deleteAnnouncement (context, { companyId, announcementId }) {
    await http.delete(`companies/${companyId}/announcements/${announcementId}`)
  }
}

// mutations
const mutations = {
  SET_CURRENT_ANNOUNCEMENT (state, value) {
    state.currentAnnouncement = { ...state.currentAnnouncement, ...value }
  },
  CLEAR_CURRENT_ANNOUNCEMENT (state) {
    state.currentAnnouncement = null
  },
  CLEAR_EDIT_ANNOUNCEMENT (state) {
    state.currentAnnouncement = null
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
