import http from '@/services/http'

const state = {
  onboardingFlows: []
}

const actions = {
  async all ({ commit }) {
    const { data: { data } } = await http.get('onboarding-flows')

    commit('SET_ONBOARDING_FLOWS', data)
  }
}

const mutations = {
  SET_ONBOARDING_FLOWS (state, value) {
    state.onboardingFlows = value
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
