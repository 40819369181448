import http from '@/services/http'

const actions = {
  async updateSectionOrder (c, payload) {
    const { data } = await http.put('section-order/update', payload)
    return data.data
  }
}

export default {
  namespaced: true,
  actions
}
