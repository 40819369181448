import http from '@/services/http'

const state = {
  conditionProperties: []
}

// getters
const getters = {}

// actions
const actions = {
  async getGroupedConditions ({ commit }, { topicId }) {
    const { data } = await http.get(`topics/${topicId}/conditionals`)
    return data.data
  },
  async getConditionProperties ({ commit }) {
    const { data } = await http.get('topic-conditional-properties')
    commit('SET_CONDITION_PROPERTIES', data.data)
  },
  async createCondition ({ commit }, { topicId, params }) {
    const { data } = await http.post(`topics/${topicId}/conditionals`, params)
    return data.data
  },
  async updateCondition ({ commit }, { topicId, conditionId, params }) {
    const { data } = await http.patch(`topics/${topicId}/conditionals/${conditionId}`, params)
    return data.data
  },
  async deleteCondition ({ commit }, { topicId, conditionId }) {
    const { data } = await http.delete(`topics/${topicId}/conditionals/${conditionId}`)
    return data.data
  }
}

// mutations
const mutations = {
  SET_CONDITION_PROPERTIES (state, value) {
    state.conditionProperties = value
  },
  CLEAR_CONDITION_PROPERTIES (state) {
    state.conditionProperties = []
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
