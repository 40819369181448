const state = {
  query: ''
}

// getters
const getters = {
  query: state => state.query
}

// mutations
const mutations = {
  SET_QUERY (state, value) {
    state.query = value
  },
  CLEAR_QUERY (state) {
    state.query = ''
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}
