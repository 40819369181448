import http from '@/services/http'

const state = {
  billingTerms: [],
  billingTerm: {},
  billingTermVersions: [],
  newVersionId: null
}

// getters
const getters = {
  hasBillingTerm (state) {
    return Object.keys(state.billingTerm).length > 0
  }
}

// actions
const actions = {
  async addVersion ({ commit }, { billingTermId }) {
    try {
      const { data } = await http.post(`billing-terms/${billingTermId}/versions`)
      commit('SET_NEW_VERSION_ID', data.data.id)
      return data.data
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async cancelVersion ({ commit }, { billingTermId, newVersionId }) {
    try {
      const { data } = await http.delete(`billing-terms/${billingTermId}/versions/${newVersionId}`)
      commit('CLEAR_NEW_VERSION_ID')
      return data.data
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async saveVersion ({ commit }, { billingTermId, newVersionId, payload }) {
    try {
      const { data } = await http.patch(`billing-terms/${billingTermId}/versions/${newVersionId}`, payload)
      commit('CLEAR_NEW_VERSION_ID')
      return data.data
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async cancelInitialVersion ({ commit }, { billingTermId }) {
    try {
      const { data } = await http.delete(`billing-terms/${billingTermId}/cancel`)
      commit('CLEAR_BILLING_TERM')
      return data.data
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async createBillingTerm ({ commit }, payload) {
    try {
      const { data } = await http.post('billing-terms', payload)
      return data.data
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async detachAssociatedCompany (c, { billingTermId, companyId }) {
    const { data } = await http.delete(`/billing-terms/${billingTermId}/companies/${companyId}`)
    return data.data
  },
  async detachAssociatedRateCard (c, { billingTermId, rateCardId }) {
    const { data } = await http.delete(`/billing-terms/${billingTermId}/rate-cards/${rateCardId}`)
    return data.data
  },
  async getBillingTerm ({ commit }, id) {
    const { data } = await http.get(`billing-terms/${id}`)
    commit('SET_BILLING_TERM', data.data)
  },
  async getBillingTermVersions ({ commit }, id) {
    const { data } = await http.get(`billing-terms/${id}/versions`)
    commit('SET_BILLING_TERM_VERSIONS', data.data)
  },
  async listAssociatedCompanies (c, billingTermId) {
    const { data } = await http.get(`/billing-terms/${billingTermId}/companies?per_page=all`)
    return data.data
  },
  async listAssociatedRateCards (c, billingTermId) {
    const { data } = await http.get(`/billing-terms/${billingTermId}/rate-cards?per_page=all`)
    return data.data
  },
  async listBillingTerms ({ commit }, query) {
    const { data } = await http.get('/billing-terms?per_page=all' + (query ? `&query=${query}` : ''))
    commit('SET_BILLING_TERMS', data.data)
  },
  async listActiveTerms ({ commit }, query) {
    const { data } = await http.get('/billing-terms?per_page=all&filter[active]=true' + (query ? `&query=${query}` : ''))
    commit('SET_BILLING_TERMS', data.data)
  },
  async syncAssociatedCompanies (c, { billingTermId, ids }) {
    const { data } = await http.post(`/billing-terms/${billingTermId}/companies/sync`, { ids })
    return data.data
  },
  async syncAssociatedRateCards (c, { billingTermId, ids }) {
    const { data } = await http.post(`/billing-terms/${billingTermId}/rate-cards/sync`, { ids })
    return data.data
  },
  async updateBillingTerm ({ commit }, { id, payload }) {
    const { data } = await http.patch(`billing-terms/${id}`, payload)
    commit('SET_BILLING_TERM', data.data)
  },
  async deleteBillingTerm ({ commit }, id) {
    await http.delete(`billing-terms/${id}`)
    commit('CLEAR_BILLING_TERM')
  },
  async restoreBillingTerm (c, id) {
    await http.post(`billing-terms/${id}/restore`)
  }
}

// mutations
const mutations = {
  SET_BILLING_TERMS (state, value) {
    state.billingTerms = value
  },
  SET_BILLING_TERM (state, value) {
    state.billingTerm = value
  },
  SET_BILLING_TERM_VERSIONS (state, value) {
    state.billingTermVersions = value
  },
  SET_NEW_VERSION_ID (state, value) {
    state.newVersionId = value
  },
  CLEAR_NEW_VERSION_ID (state) {
    state.newVersionId = null
  },
  CLEAR_BILLING_TERM_VERSIONS (state) {
    state.billingTermVersions = []
  },
  CLEAR_BILLING_TERM (state) {
    state.billingTerm = {}
  },
  CLEAR_BILLING_TERMS (state) {
    state.billingTerms = {}
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
