import http from '@/services/http'

const state = {
  favorites: []
}

// getters
const getters = {}

// actions
const actions = {
  async getFavorites ({ commit }, workOrderId) {
    try {
      const { data } = await http.get(`work-orders/${workOrderId}/favorites`)
      commit('SET_FAVORITES', data.data)
    } catch (e) {
      return Promise.reject(e)
    }
  }
}

// mutations
const mutations = {
  SET_FAVORITES (state, value) {
    state.favorites = value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
