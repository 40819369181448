import http from '@/services/http'

const state = {
  editingField: {},
  fieldTypes: []
}

// getters
const getters = { }

// actions
const actions = {
  async createField (c, { form, section, ...params }) {
    const { data } = await http.post(`forms/${form}/sections/${section}/fields`, params)

    return data.data
  },
  async deleteField ({ commit }, { formId, sectionId, fieldId }) {
    const { data } = await http.delete(`forms/${formId}/sections/${sectionId}/fields/${fieldId}`)
    return data.data
  },
  async getPreview (c, value) {
    value = encodeURIComponent(value)
    const { data } = await http.get(`form-builder/fields/date/preview?parse=${value}`)
    return data
  },
  async listFieldTypes ({ commit }) {
    const { data } = await http.get('form-builder/fields')
    commit('SET_FIELD_TYPES', data.data)
  },
  async sortFields (c, { formId, sectionId, ...params }) {
    const { data } = await http.post(`forms/${formId}/sections/${sectionId}/fields/order`, params)
    return data.data
  },
  async updateField ({ commit }, { formId, sectionId, fieldId, payload }) {
    const { data } = await http.patch(`forms/${formId}/sections/${sectionId}/fields/${fieldId}`, payload)
    commit('SET_EDITING_FIELD', data.data)
  }
}

// mutations
const mutations = {
  CLEAR_EDITING_FIELD (state) {
    state.editingField = {}
  },
  SET_EDITING_FIELD (state, value) {
    state.editingField = { ...state.editingField, ...value }
  },
  SET_FIELD_TYPES (state, value) {
    state.fieldTypes = value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
