import http from '@/services/http'

const state = {}

// getters
const getters = {}

// actions
const actions = {
  async availability (c, { userId, ...payload }) {
    const { data } = await http.get(`users/${userId}/calendar/free-times`, { params: payload })

    return data.data
  }
}

// mutations
const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
