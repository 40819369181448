import http from '@/services/http'

const state = {
  activities: []
}
// getters
const getters = {
  visibleActivities: state => state.activities.filter(activity => activity.visible)
}

// actions
const actions = {
  async getActivity ({ commit }, workOrderId) {
    try {
      const { data } = await http.get(`work-orders/${workOrderId}/activity`, {
        per_page: 'all'
      })
      commit('SET_ACTIVITIES', data.data)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async retryAction ({ commit }, { workOrderId, actionId }) {
    try {
      const { data } = await http.post(`work-orders/${workOrderId}/actions/${actionId}/retry`)
      return data.data
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async updateAction ({ commit }, { workOrderId, action, actionValues }) {
    try {
      const { data } = await http.patch(`work-orders/${workOrderId}/actions/${action.id}`, actionValues)
      return data.data
    } catch (e) {
      return Promise.reject(e)
    }
  }
}
// mutations
const mutations = {
  SET_ACTIVITIES (state, value) {
    state.activities = value
  },
  CLEAR_ACTIVITIES (state) {
    state.activities = []
  }
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
