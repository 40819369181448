import http from '@/services/http'
const state = {
  events: []
}

const getters = {}
// actions
const actions = {
  async getEvents ({ commit }, workOrderId) {
    try {
      const { data } = await http.get(`work-orders/${workOrderId}/event-metrics`)
      commit('SET_EVENTS', data.data)
    } catch (e) {
      return Promise.reject(e)
    }
  }
}
// mutations
const mutations = {
  SET_EVENTS (state, value) {
    state.events = value
  }
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
