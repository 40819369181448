import http from '@/services/http'

const state = {
  services: [],
  service: {},
  serviceCategories: []
}

// getters
const getters = {
  hasService (state) {
    return Object.keys(state.service).length > 0
  }
}

// actions
const actions = {
  async createService ({ commit }, payload) {
    try {
      const { data } = await http.post('services', payload)
      return data.data
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async getService ({ commit }, id) {
    const { data } = await http.get(`services/${id}`)
    commit('SET_SERVICE', data.data)
  },
  async listServices ({ commit }, query) {
    const { data } = await http.get('/services' + (query ? `&query=${query}` : ''))
    commit('SET_SERVICES', data.data)
  },
  async listServiceCategories ({ commit }) {
    const { data } = await http.get('services/categories')
    commit('SET_SERVICE_CATEGORIES', data.data)
  },
  async syncAssociatedPrograms (c, { serviceId, ids }) {
    const { data } = await http.post(`/services/${serviceId}/programs/sync`, { ids })
    return data.data
  },
  async updateService ({ commit }, { id, payload }) {
    const { data } = await http.patch(`services/${id}`, payload)
    commit('SET_SERVICE', data.data)
  },
  async deleteService ({ commit }, id) {
    await http.delete(`services/${id}`)
    commit('CLEAR_SERVICE')
  }
}

// mutations
const mutations = {
  SET_SERVICES (state, value) {
    state.services = value
  },
  SET_SERVICE (state, value) {
    state.service = { ...state.service, ...value }
  },
  SET_SERVICE_CATEGORIES (state, value) {
    state.serviceCategories = value
  },
  CLEAR_SERVICE (state) {
    state.service = {}
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
