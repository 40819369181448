<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    active: {
      type: String,
      default: 'Active'
    },
    inactive: {
      type: String,
      default: 'Inactive'
    }
  }
}
</script>

<template>
  <div class="relative flex items-start">
    <div class="flex items-center h-5">
      <r-switch
        v-model="value"
        icon
        v-bind="$attrs"
        v-on="$listeners"
      />
    </div>
    <div class="ml-3 text-sm leading-5">
      <label
        :for="$attrs.for"
        class="font-medium text-gray-700"
      > {{ value ? active : inactive }}</label>
    </div>
  </div>
</template>
