import http from '@/services/http'

const state = {
  currentServiceProvider: null,
  serviceProviders: []
}

const getters = {}

const actions = {
  async list ({ commit }, id) {
    try {
      const { data } = await http.get('service-providers?per_page=all')
      commit('SET_SERVICE_PROVIDERS', data.data)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async get ({ commit }, id) {
    try {
      const { data } = await http.get(`service-providers/${id}`)
      commit('SET_CURRENT_SERVICE_PROVIDER', data.data)
      return data.data
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async create ({ commit }, payload) {
    try {
      return await http.post('service-providers', payload)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async update ({ commit }, { id, payload }) {
    try {
      const { data } = await http.patch(`service-providers/${id}`, payload)
      commit('UPDATE_CURRENT_SERVICE_PROVIDER', data.data)
      return data.data
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async delete ({ commit }, id) {
    try {
      return await http.delete(`service-providers/${id}`)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async restore ({ commit }, id) {
    try {
      return await http.post(`service-providers/${id}/restore`)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async createUser (c, params) {
    try {
      return await http.post(`service-providers/${params.id}/users`, params.user)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async deleteUser (c, params) {
    try {
      await http.delete(`service-providers/${params.id}/users/${params.user.id}`)
    } catch (e) {
      return Promise.reject(e)
    }
  }
}

const mutations = {
  SET_CURRENT_SERVICE_PROVIDER (state, value) {
    state.currentServiceProvider = value
  },
  UPDATE_CURRENT_SERVICE_PROVIDER (state, value) {
    state.currentServiceProvider = Object.assign(state.currentServiceProvider, value)
  },
  SET_SERVICE_PROVIDERS (state, value) {
    state.serviceProviders = value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
