import http from '@/services/http'
import { isEmpty, head, get } from '@/utils/lodash'
import { difference } from '@/utils/helpers'
import { BOOKING } from '@/utils/timesheet-types'

const state = {
  timesheet: {},
  frozenTimesheet: {},
  currentTimesheetDuration: null,
  filterStartDate: null,
  filterEndDate: null,
  currentAttestation: {},
  allowAttestation: false
}

// getters
const getters = {
  userId: (state, getters, rootState, rootGetters) => rootGetters['user/id'],
  hasTimesheet: state => !isEmpty(state.timesheet),
  timesheetChanges: state => difference(state.frozenTimesheet, state.timesheet),
  hasChanges: (state, getters) => !isEmpty(getters.timesheetChanges),
  hasChange: (state, getters) => (field) => getters.hasChanges && getters.timesheetChanges[field] !== undefined,
  hasCurrentTimesheet: (state, getters) => getters.hasTimesheet && get(state.timesheet, 'duration') === 0,
  isBooking: state => get(state.timesheet, 'type') === BOOKING,
  hasFilterDates: state => state.filterStartDate !== null,
  queryStartDate: (state, getters, rootState) => get(rootState, 'route.query.startDate'),
  queryEndDate: (state, getters, rootState) => get(rootState, 'route.query.endDate')
}

// actions
const actions = {
  async searchClients ({ getters, commit }, query) {
    const { data } = await http.get(`users/${getters.userId}/timesheets/clients?filter[query]=${query}`)

    return data.data
  },
  async findClient ({ getters, commit }, id) {
    const { data } = await http.get(`users/${getters.userId}/timesheets/clients?filter[id]=${id}`)

    return head(data.data)
  },
  async get ({ getters, commit }, id) {
    const { data } = await http.get(`users/${getters.userId}/timesheets/${id}`)

    commit('SET_USER_TIMESHEET_RECORD', data.data)

    return data.data
  },
  async summary ({ getters }, { start, end }) {
    const { data } = await http.get(`users/${getters.userId}/timesheets/summary`, { params: { from_date: start, to_date: end } })

    return data.data
  },
  async update ({ getters }, { id, ...payload }) {
    const { data } = await http.patch(`users/${getters.userId}/timesheets/${id}`, payload)

    return data.data
  },
  async batchUpdate ({ getters }, payload) {
    const { data } = await http.post(`users/${getters.userId}/timesheets/batch-update`, payload)

    return data.data
  },
  async beginAttestation ({ getters, commit }, payload) {
    const { data } = await http.post(`users/${getters.userId}/timesheet-attestations`, payload)
    commit('SET_CURRENT_ATTESTATION', data.data)
    return data.data
  },
  async finalizeAttestation ({ getters, commit }, payload) {
    const { data } = await http.put(`users/${getters.userId}/timesheet-attestations/${state.currentAttestation.id}`, payload)
    commit('CLEAR_CURRENT_ATTESTATION')
    return data.data
  },
  async getAllowAttestation ({ getters, commit }, { startDate, endDate }) {
    commit('CLEAR_CURRENT_ATTESTATION')
    const { data } = await http.get(`users/${getters.userId}/timesheet-attestations/allow?start_date=${startDate}&end_date=${endDate}`)
    commit('SET_CURRENT_ATTESTATION', data.data)
    return data.data
  },
  async clockTimesheet (c, { userId, ...payload }) {
    const { data } = await http.post(`users/${userId}/timesheets`, payload)

    return data.data
  },
  async current ({ commit, getters }) {
    const { data } = await http.get(`users/${getters.userId}/timesheets/current`)

    commit('SET_USER_TIMESHEET_RECORD', data.data)

    return data.data
  },
  async delete ({ getters }, id) {
    const { data } = await http.delete(`users/${getters.userId}/timesheets/${id}`)

    return data.data
  },
  clear ({ commit }) {
    commit('CLEAR_USER_TIMESHEET_RECORD')
  },
  setFilterDates ({ commit }, { startDate, endDate }) {
    commit('SET_FILTER_START_DATE', startDate)
    commit('SET_FILTER_END_DATE', endDate)
  }
}

// mutations
const mutations = {
  SET_USER_TIMESHEET_RECORD (state, row) {
    state.timesheet = row
    state.frozenTimesheet = Object.freeze(state.timesheet)
  },
  CLEAR_USER_TIMESHEET_RECORD (state) {
    state.timesheet = {}
    state.frozenTimesheet = {}
  },
  UPDATE_USER_TIMESHEET_RECORD (state, payload) {
    state.timesheet = { ...state.timesheet, ...payload }
  },
  SET_CURRENT_TIMESHEET_DURATION (state, value) {
    state.currentTimesheetDuration = value
  },
  CLEAR_CURRENT_TIMESHEET_DURATION (state) {
    state.currentTimesheetDuration = null
  },
  SET_FILTER_START_DATE (state, value) {
    state.filterStartDate = value
  },
  SET_FILTER_END_DATE (state, value) {
    state.filterEndDate = value
  },
  SET_CURRENT_ATTESTATION (state, value) {
    state.currentAttestation = value
  },
  CLEAR_CURRENT_ATTESTATION (state) {
    state.currentAttestation = {}
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
