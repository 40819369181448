import http from '@/services/http'
import axios from 'axios'
import { head } from '@/utils/lodash'

const state = {}

const getters = {}

const actions = {
  async sign (c, { name, type }) {
    const { data } = await http.post('storage/signed-upload-urls', { name, content_type: type })

    return head(data.data)
  },
  async store (c, { url, file }) {
    const data = await axios.put(url, file, {
      headers: {
        'Content-Type': 'application/octet-stream'
      }
    })

    return data
  }
}

const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
