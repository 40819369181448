import http from '@/services/http'

const state = {
  fieldSection: null,
  form: {},
  formPolicies: [],
  showSections: false,
  showBuilder: true,
  showPreview: true
}

// getters
const getters = { }

// actions
const actions = {
  async createForm (c, form) {
    const { data } = await http.post('forms', form)
    return data.data
  },
  async deleteForm (c, formId) {
    const { data } = await http.delete(`forms/${formId}`)
    return data.data
  },
  async detachAssociatedPolicy (c, { formId, id }) {
    const { data } = await http.delete(`/forms/${formId}/policies/${id}/detach`)
    return data.data
  },
  async getForm ({ commit }, formId) {
    const { data } = await http.get(`forms/${formId}`)
    commit('SET_FORM', data.data)
  },
  async listFormPolicies ({ commit }, formId) {
    const { data } = await http.get(`forms/${formId}/policies?per_page=all`)
    commit('SET_FORM_POLICIES', data.data)
  },
  async syncAssociatedPolicies (c, { formId, ids }) {
    const { data } = await http.post(`/forms/${formId}/policies/sync`, { ids })
    return data.data
  },
  async updateForm ({ commit }, { formId, payload }) {
    const { data } = await http.patch(`forms/${formId}`, payload)
    commit('SET_FORM', data.data)
  }
}

// mutations
const mutations = {
  CLEAR_FORM (state, value) {
    state.form = {}
  },
  SET_FORM (state, value) {
    state.form = { ...state.form, ...value }
  },
  SET_FIELD_SECTION (state, value) {
    state.fieldSection = value
  },
  SET_FORM_POLICIES (state, value) {
    state.formPolicies = value
  },
  SET_SHOW_BUILDER (state, value) {
    state.showBuilder = value
  },
  SET_SHOW_PREVIEW (state, value) {
    state.showPreview = value
  },
  SET_SHOW_SECTIONS (state, value) {
    state.showSections = value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
