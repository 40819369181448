import http from '@/services/http'

const state = {
  currencies: []
}

// getters
const getters = {}

// actions
const actions = {
  async listCurrencies ({ commit }) {
    try {
      const { data } = await http.get('currencies')
      commit('SET_CURRENCIES', data.data)
    } catch (e) {
      return Promise.reject(e)
    }
  }
}

// mutations
const mutations = {
  SET_CURRENCIES (state, value) {
    state.currencies = value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
