<template>
  <div
    v-if="minimal"
    class="text-center text-white border-2 border-white rounded-full"
  >
    <img
      src="https://cdn.relocity.com/assets/images/relocity-logo-round-blk.svg"
      alt="Relocity Logo"
      class="w-15 h-15"
    >
  </div>
  <div
    v-else
    class="w-full text-center"
  >
    <r-icon
      size="2x"
      color="white"
      name="relocity-logo"
      icon-style="fak"
    />
    <h4 class="text-white text-sm whitespace-no-wrap mobility-experience-platform">
      Mobility Experience Platform
    </h4>
  </div>
</template>

<script>
export default {
  props: {
    minimal: {
      type: Boolean,
      default: false
    }
  }
}
</script>
