import http from '@/services/http'
const state = {
  serviceCategories: [],
  serviceCategory: {},
  serviceCategoryTags: [],
  serviceCategoryModelLabels: [],
  prohibitedServiceCategories: []
}
// getters
const getters = {
  uiTags (state) {
    const arr = []
    state.serviceCategoryTags.forEach(tag => {
      arr.push({ text: tag.name })
    })
    return arr
  },
  uiModelLabels (state) {
    const arr = []
    state.serviceCategoryModelLabels.forEach(modelLabel => {
      arr.push({ text: modelLabel.name })
    })
    return arr
  }
}
// actions
const actions = {
  async createServiceCategory ({ commit }, { id, payload }) {
    try {
      const { data } = await http.post('service-categories', payload)
      commit('SET_SERVICE_CATEGORY', data.data)
      return data.data
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async deleteServiceCategory ({ commit }, id) {
    try {
      return await http.delete(`service-categories/${id}`)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async getServiceCategory ({ commit }, id) {
    try {
      const { data } = await http.get(`service-categories/${id}`)
      commit('SET_SERVICE_CATEGORY', data.data)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async getServiceCategoryTags ({ commit }, id) {
    try {
      const { data } = await http.get(`service-categories/${id}/tags`)
      commit('SET_SERVICE_CATEGORY_TAGS', data.data)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async getServiceCategoryModelLabels ({ commit }, id) {
    try {
      const { data } = await http.get(`service-categories/${id}/model-labels`)
      commit('SET_SERVICE_CATEGORY_MODEL_LABELS', data.data)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async list ({ commit }, perPage = 15) {
    try {
      const { data } = await http.get(`service-categories?per_page=${perPage}`)
      commit('SET_SERVICE_CATEGORIES', data.data)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async restoreServiceCategory (c, serviceCategoryId) {
    await http.post(`service-categories/${serviceCategoryId}/restore`)
  },
  async updateServiceCategory ({ commit }, { id, payload }) {
    try {
      const { data } = await http.patch(`service-categories/${id}`, payload)
      commit('SET_SERVICE_CATEGORY', data.data)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async updateServiceCategoryTags ({ commit }, { id, payload }) {
    try {
      const { data } = await http.put(`service-categories/${id}/tags`, payload)
      commit('SET_SERVICE_CATEGORY_TAGS', data.data)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async updateServiceCategoryModelLabels ({ commit }, { id, payload }) {
    try {
      const { data } = await http.put(`service-categories/${id}/model-labels`, payload)
      commit('SET_SERVICE_CATEGORY_MODEL_LABELS', data.data)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async listProhibited ({ commit }, companyId) {
    const { data } = await http.get(`/companies/${companyId}/prohibited-service-categories`)
    commit('SET_PROHIBITED_SERVICE_CATEGORIES', data.data)
  },
  async syncProhibited (c, { companyId, ids }) {
    const { data } = await http.put(`/companies/${companyId}/prohibited-service-categories/`, { ids })
    return data.data
  }
}
// mutations
const mutations = {
  CLEAR_SERVICE_CATEGORY (state) {
    state.serviceCategory = {}
  },
  CLEAR_SERVICE_CATEGORY_TAGS (state) {
    state.serviceCategoryTags = []
  },
  CLEAR_SERVICE_CATEGORY_MODEL_LABELS (state) {
    state.serviceCategoryModelLabels = []
  },
  SET_SERVICE_CATEGORIES (state, value) {
    state.serviceCategories = value
  },
  SET_SERVICE_CATEGORY (state, value) {
    state.serviceCategory = { ...state.serviceCategory, ...value }
  },
  SET_SERVICE_CATEGORY_TAGS (state, value) {
    state.serviceCategoryTags = value
  },
  SET_SERVICE_CATEGORY_MODEL_LABELS (state, value) {
    state.serviceCategoryModelLabels = value
  },
  SET_PROHIBITED_SERVICE_CATEGORIES (state, value) {
    state.prohibitedServiceCategories = value
  }
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
