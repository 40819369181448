import http from '@/services/http'
const state = {
  products: [],
  product: {}
}
// getters
const getters = {
}
// actions
const actions = {
  async createProduct ({ commit }, { payload }) {
    try {
      const { data } = await http.post('products', payload)
      commit('SET_PRODUCT', data.data)
      return data.data
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async deleteProduct ({ commit }, { productId }) {
    try {
      return await http.delete(`products/${productId}`)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async getProduct ({ commit }, { productId }) {
    try {
      const { data } = await http.get(`products/${productId}`)
      commit('SET_PRODUCT', data.data)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async listProducts ({ commit }) {
    try {
      const { data } = await http.get('products')
      commit('SET_PRODUCTS', data.data)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async restoreProduct (c, { productId }) {
    await http.post(`products/${productId}/restore`)
  },
  async updateProduct ({ commit }, { productId, payload }) {
    try {
      const { data } = await http.patch(`products/${productId}`, payload)
      commit('SET_PRODUCT', data.data)
    } catch (e) {
      return Promise.reject(e)
    }
  }
}
// mutations
const mutations = {
  CLEAR_PRODUCT (state) {
    state.product = {}
  },
  SET_PRODUCTS (state, value) {
    state.products = value
  },
  SET_PRODUCT (state, value) {
    state.product = { ...state.product, ...value }
  }
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
