import router from '@/router'
import { isEqual, isArray, isObject, transform } from 'utils/lodash'
import { clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'

export function logout () {
  localStorage.clear()
  router.app.$auth.logout()
}

export function difference (origObj, newObj) {
  function changes (newObj, origObj) {
    let arrayIndexCounter = 0
    return transform(newObj, function (result, value, key) {
      if (!isEqual(value, origObj[key])) {
        const resultKey = isArray(origObj) ? arrayIndexCounter++ : key
        result[resultKey] = (isObject(value) && isObject(origObj[key])) ? changes(value, origObj[key]) : value
      }
    })
  }
  return changes(newObj, origObj)
}

export function mergeById (a1, a2) {
  return a1.map(itm => ({
    ...itm,
    ...a2.find((item) => (item.id === itm.id) && item)
  }))
}

export function capitalize (word) {
  const lower = word.toLowerCase()
  return word.charAt(0).toUpperCase() + lower.slice(1)
}

export async function parsePhoneNumber (number) {
  const { parsePhoneNumberFromString } = await import('libphonenumber-js')

  return parsePhoneNumberFromString(number)
};

export function convertToHours (seconds) {
  if (seconds >= 60) {
    const hours = Math.floor(seconds / 3600)
    return hours > 0 ? hours + (hours === 1 ? ' Hour ' : ' Hours ') : ''
  } else {
    return seconds + ' Seconds'
  }
}

export function convertToHoursAndMinutes (seconds, roundHalfHour = false, roundHour = false, displaySeconds = true) {
  if (seconds < 60) {
    return displaySeconds ? seconds + ' Seconds' : seconds
  }
  if (roundHour) {
    return convertToHours(seconds)
  }

  let hours = Math.floor(seconds / 3600)
  let minutes = Math.floor((seconds % 3600) / 60)
  if (roundHalfHour && minutes > 0 && minutes <= 30) {
    minutes = 30
  } else if (roundHalfHour && minutes > 30) {
    hours += 1
    minutes = 0
  }
  const hourDisplay = hours > 0 ? hours + (hours === 1 ? ' Hour ' : ' Hours ') : ''
  const minuteDisplay = minutes > 0 ? minutes + (minutes === 1 ? ' Minute ' : ' Minutes') : ''
  const conversion = hourDisplay + minuteDisplay
  return conversion.trimRight()
}

export function arrToObj (arr) {
  return arr.reduce((acc, currentValue) => {
    acc[currentValue] = true
    return acc
  }, {})
}

export function userShortName (user) {
  const lastNameInitial = `${user.last_name.charAt(0)}.`
  return `${user.first_name} ${lastNameInitial}`
}

export function cn (...inputs) {
  return twMerge(clsx(inputs))
}

export function dotNotationToNestedObject (obj, key, value) {
  const keys = key.split('.')
  const newObject = { ...obj } // Create a copy of the original object
  let current = newObject

  for (let i = 0; i < keys.length - 1; i++) {
    // Create a new nested object if it doesn't exist, ensuring immutability
    current[keys[i]] = current[keys[i]] ? { ...current[keys[i]] } : {}
    current = current[keys[i]]
  }

  // Set the value at the final key
  current[keys[keys.length - 1]] = value

  return newObject // Return the new object with the nested structure
}
