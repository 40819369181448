import http from '@/services/http'

const state = {}

// getters
const getters = {}

// actions
const actions = {
  async attachCoHost ({ commit }, { workOrderId, payload }) {
    try {
      const { data } = await http.post(`work-orders/${workOrderId}/co-hosts/attach`, payload)
      return data.data
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async detachCoHost ({ commit }, { workOrderId, coHostId }) {
    try {
      const { data } = await http.delete(`work-orders/${workOrderId}/co-hosts/detach/${coHostId}`)
      return data.data
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
// mutations
const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
