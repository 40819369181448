import DashboardBase from '@/views/dashboard/base.vue'
import WorkOrderBase from '@/views/work-orders/base.vue'
import CompaniesBase from '@/views/companies/base.vue'
import { RelocityAuthCallback } from '@relocity/auth'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () =>
      import(/* webpackChunkName: "home" */ '@/views/index/index.vue')
  },
  {
    path: '/notifications/callback',
    name: 'notifications.callback',
    component: () => import('@/views/notifications/index.vue')
  },
  {
    path: '/users',
    component: DashboardBase,
    children: [
      {
        path: '',
        name: 'users.index',
        props: route => ({ query: route.query.query }),
        component: () =>
          import(/* webpackChunkName: "users" */ '@/views/users/index.vue')
      },
      {
        path: ':userId',
        props: true,
        component: () =>
          import(/* webpackChunkName: "user-edit" */ '@/views/users/base.vue'),
        children: [
          {
            path: '',
            props: true,
            name: 'users.edit',
            component: () =>
              import(/* webpackChunkName: "user-edit" */ '@/views/users/details.vue')
          },
          {
            path: 'employee-profile',
            props: true,
            name: 'users.employee.profile',
            component: () =>
              import(/* webpackChunkName: "user-profile" */ '@/views/users/employee-profile.vue')
          },
          {
            path: 'work-orders',
            props: true,
            name: 'users.work.orders',
            component: () =>
              import(/* webpackChunkName: "work-orders" */ '@/views/users/work-orders.vue')
          },
          {
            path: 'timesheets',
            props: true,
            name: 'users.timesheets',
            component: () =>
              import(/* webpackChunkName: "user-timesheets" */ '@/views/users/timesheets/index.vue')
          }
        ]
      },
      {
        path: ':userId/timesheets',
        props: true,
        component: () =>
          import(/* webpackChunkName: "users.timesheets.detail" */ '@/views/users/timesheets/base.vue'),
        children: [
          {
            path: ':timesheetId',
            props: true,
            component: () =>
              import(/* webpackChunkName: "users.timesheets.detail" */ '@/views/users/timesheets/edit.vue'),
            children: [
              {
                path: '',
                name: 'users.timesheets.view',
                props: true,
                component: () =>
                  import(/* webpackChunkName: "users.timesheets.overview" */ '@/views/users/timesheets/overview.vue')
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: '/areas',
    component: DashboardBase,
    children: [
      {
        path: '',
        name: 'areas.index',
        component: () =>
          import(/* webpackChunkName: "areas" */ '@/views/areas/index.vue')
      },
      {
        path: 'create',
        name: 'areas.create',
        component: () =>
          import(/* webpackChunkName: "areas-edit" */ '@/views/areas/edit.vue')
      },
      {
        path: ':areaId',
        props: true,
        name: 'areas.edit',
        component: () =>
          import(/* webpackChunkName: "areas-edit" */ '@/views/areas/edit.vue')
      }
    ]
  },
  {
    path: '/companies',
    component: CompaniesBase,
    children: [
      {
        path: '',
        name: 'companies.index',
        props: route => ({ query: route.query.query }),
        component: () =>
          import(/* webpackChunkName: "companies" */ '@/views/companies/index.vue')
      },
      {
        path: 'onboard',
        props: route => ({ name: route.query.name }),
        name: 'companies.onboard',
        component: () =>
          import(/* webpackChunkName: "companies-onboard" */ '@/views/companies/onboard.vue')
      },
      {
        path: ':companyId',
        props: true,
        name: 'companies.edit',
        component: () =>
          import(/* webpackChunkName: "companies-edit" */ '@/views/companies/edit.vue')
      }
    ]
  },
  {
    path: '/services',
    component: DashboardBase,
    children: [
      {
        path: '',
        name: 'services.index',
        props: route => ({ query: route.query.query }),
        component: () =>
          import(/* webpackChunkName: "services" */ '@/views/services/index.vue')
      },
      {
        path: 'create',
        name: 'services.create',
        props: true,
        component: () =>
          import(/* webpackChunkName: "services" */ '@/views/services/edit.vue')
      },
      {
        path: ':serviceId',
        name: 'services.edit',
        props: true,
        component: () =>
          import(/* webpackChunkName: "services" */ '@/views/services/edit.vue')
      }
    ]
  },
  {
    path: '/billing-terms',
    component: DashboardBase,
    children: [
      {
        path: '',
        name: 'billing-terms.index',
        props: route => ({ query: route.query.query }),
        component: () =>
          import(/* webpackChunkName: "billing-terms" */ '@/views/billing-terms/index.vue')
      },
      {
        path: 'create',
        name: 'billing-terms.create',
        props: true,
        component: () =>
          import(/* webpackChunkName: "billing-terms" */ '@/views/billing-terms/edit.vue')
      },
      {
        path: ':billingTermId',
        name: 'billing-terms.edit',
        props: true,
        component: () =>
          import(/* webpackChunkName: "billing-terms" */ '@/views/billing-terms/edit.vue')
      }
    ]
  },
  {
    path: '/policies',
    component: DashboardBase,
    children: [
      {
        path: '',
        name: 'policies.index',
        props: route => ({ query: route.query.query }),
        component: () =>
          import(/* webpackChunkName: "policies" */ '@/views/policies/index.vue')
      },
      {
        path: ':policyId',
        props: true,
        name: 'policies.edit',
        component: () =>
          import(/* webpackChunkName: "policies-edit" */ '@/views/policies/edit.vue')
      }
    ]
  },
  {
    path: '/policy-options',
    component: DashboardBase,
    children: [
      {
        path: '',
        name: 'policy-options.index',
        props: route => ({ query: route.query.query }),
        component: () =>
          import(/* webpackChunkName: "policy-options" */ '@/views/policy-options/index.vue')
      },
      {
        path: ':policyOptionId',
        props: true,
        name: 'policy-options.edit',
        component: () =>
          import(/* webpackChunkName: "policy-options-edit" */ '@/views/policy-options/edit.vue')
      }
    ]
  },
  {
    path: '/forms',
    component: DashboardBase,
    children: [
      {
        path: '',
        name: 'forms.index',
        props: route => ({ query: route.query.query }),
        component: () =>
          import(/* webpackChunkName: "forms" */ '@/views/forms/index.vue')
      },
      {
        path: ':formId',
        props: true,
        name: 'forms.edit',
        component: () =>
          import(/* webpackChunkName: "forms-edit" */ '@/views/forms/edit.vue')
      }
    ]
  },
  {
    path: '/goals',
    component: DashboardBase,
    children: [
      {
        path: '',
        name: 'goals.index',
        props: route => ({ query: route.query.query }),
        component: () =>
          import(/* webpackChunkName: "goals" */ '@/views/goals/index.vue')
      },
      {
        path: 'goal/:goalId',
        props: true,
        name: 'goals.edit',
        component: () =>
          import(/* webpackChunkName: "goals-edit" */ '@/views/goals/edit.vue')
      }
    ]
  },
  {
    path: '/topics',
    component: DashboardBase,
    children: [
      {
        path: '',
        name: 'topics.index',
        component: () =>
          import(/* webpackChunkName: "topics" */ '@/views/topics/index.vue')
      },
      {
        path: 'topic/:topicId',
        props: true,
        name: 'topics.edit',
        component: () =>
          import(/* webpackChunkName: "topics-edit" */ '@/views/topics/edit.vue')
      }
    ]
  },
  {
    path: '/codes',
    component: DashboardBase,
    children: [
      {
        path: '',
        name: 'codes.index',
        component: () =>
          import(/* webpackChunkName: "codes" */ '@/views/codes/index.vue')
      },
      {
        path: 'codes/:id',
        props: true,
        name: 'codes.edit',
        component: () =>
          import(/* webpackChunkName: "codes-edit" */ '@/views/codes/edit.vue')
      }
    ]
  },
  {
    path: '/announcements',
    component: DashboardBase,
    children: [
      {
        path: '',
        name: 'announcements.index',
        props: route => ({ query: route.query.query }),
        component: () =>
          import(/* webpackChunkName: "announcements" */ '@/views/announcements/index.vue')
      },
      {
        path: 'company/:companyId/announcement/create',
        name: 'announcements.create',
        props: true,
        component: () =>
          import(/* webpackChunkName: "announcements-edit" */ '@/views/announcements/edit.vue')
      },
      {
        path: 'company/:companyId/announcement/:announcementId',
        props: true,
        name: 'announcements.edit',
        component: () =>
          import(/* webpackChunkName: "announcements-edit" */ '@/views/announcements/edit.vue')
      }
    ]
  },
  {
    path: '/transferees',
    component: DashboardBase,
    children: [
      {
        path: '',
        name: 'transferees.index',
        props: route => ({ query: route.query.query }),
        component: () =>
          import(/* webpackChunkName: "transferees" */ '@/views/transferees/index.vue')
      },
      {
        path: ':workOrderId',
        props: true,
        name: 'transferees.view',
        component: () =>
          import(/* webpackChunkName: "transferees-view" */ '@/views/transferees/view.vue')
      }
    ]
  },
  {
    path: '/service-categories',
    component: DashboardBase,
    children: [
      {
        path: '',
        name: 'service-categories.index',
        props: route => ({ query: route.query.query }),
        component: () =>
          import(/* webpackChunkName: "service-categories" */ '@/views/service-categories/index.vue')
      },
      {
        path: 'create',
        name: 'service-categories.create',
        props: true,
        component: () =>
          import(/* webpackChunkName: "service-categories" */ '@/views/service-categories/edit.vue')
      },
      {
        path: ':serviceCategoryId',
        name: 'service-categories.edit',
        props: true,
        component: () =>
          import(/* webpackChunkName: "service-categories" */ '@/views/service-categories/edit.vue')
      }
    ]
  },
  {
    path: '/products',
    component: DashboardBase,
    children: [
      {
        path: '',
        name: 'products.index',
        props: route => ({ query: route.query.query }),
        component: () =>
          import(/* webpackChunkName: "products" */ '@/views/products/index.vue')
      },
      {
        path: 'create',
        name: 'products.create',
        props: true,
        component: () =>
          import(/* webpackChunkName: "products" */ '@/views/products/edit.vue')
      },
      {
        path: ':productId',
        name: 'products.edit',
        props: true,
        component: () =>
          import(/* webpackChunkName: "products" */ '@/views/products/edit.vue')
      }
    ]
  },
  {
    path: '/work-orders',
    component: WorkOrderBase,
    children: [
      {
        path: '',
        name: 'work-orders.index',
        props: route => ({ query: route.query.query }),
        component: () =>
          import(/* webpackChunkName: "work-orders" */ '@/views/work-orders/index.vue')
      },
      {
        path: 'create',
        name: 'work-orders.create',
        props: true,
        component: () =>
          import(/* webpackChunkName: "work-orders" */ '@/views/work-orders/create.vue')
      },
      {
        path: ':workOrderId',
        props: true,
        component: () =>
          import(/* webpackChunkName: "work-orders" */ '@/views/work-orders/edit.vue'),
        children: [
          {
            path: '',
            redirect: { name: 'work-orders.activity' },
            props: true,
            component: () =>
              import(/* webpackChunkName: "work-orders.overview" */ '@/views/work-orders/edit/overview.vue'),
            children: [
              {
                path: 'activity',
                props: true,
                name: 'work-orders.activity',
                component: () =>
                  import(/* webpackChunkName: "work-orders.activity" */ '@/views/work-orders/edit/activity.vue')
              },
              {
                path: 'validations',
                props: true,
                name: 'work-orders.validations',
                component: () =>
                  import(/* webpackChunkName: "work-orders.events" */ '@/views/work-orders/edit/validations.vue')
              },
              {
                path: 'events',
                props: true,
                name: 'work-orders.events',
                component: () =>
                  import(/* webpackChunkName: "work-orders.events" */ '@/views/work-orders/edit/events.vue')
              }
            ]
          },
          {
            path: 'details',
            props: true,
            name: 'work-orders.details',
            component: () =>
              import(/* webpackChunkName: "work-orders.details" */ '@/views/work-orders/edit/form.vue')
          },
          {
            path: 'add-ons',
            props: true,
            name: 'work-orders.add-ons',
            component: () =>
              import(/* webpackChunkName: "work-orders.add-ons" */ '@/views/work-orders/edit/add-ons.vue')
          },
          {
            path: 'profile',
            props: true,
            name: 'work-orders.profile',
            component: () =>
              import(/* webpackChunkName: "work-orders.profile" */ '@/views/work-orders/edit/profile.vue')
          },
          {
            path: 'favorites',
            props: true,
            name: 'work-orders.favorites',
            component: () =>
              import(/* webpackChunkName: "work-orders.favorites" */ '@/views/work-orders/edit/favorites.vue')
          },
          {
            path: 'notes',
            props: true,
            name: 'work-orders.notes',
            component: () =>
              import(/* webpackChunkName: "work-orders.notes" */ '@/views/work-orders/edit/notes.vue')
          },
          {
            path: 'documents',
            props: true,
            name: 'work-orders.documents',
            component: () =>
              import(/* webpackChunkName: "work-orders.notes" */ '@/views/work-orders/edit/documents.vue')
          },
          {
            path: 'allocated-hours',
            props: true,
            name: 'work-orders.allocated-hours',
            component: () =>
              import(/* webpackChunkName: "work-orders.allocated-hours" */ '@/views/work-orders/edit/allocated-hours.vue')
          },
          {
            path: 'transactions',
            props: true,
            name: 'work-orders.transactions',
            component: () =>
              import(/* webpackChunkName: "work-orders.transactions" */ '@/views/work-orders/edit/transactions.vue')
          },
          {
            path: 'bookings',
            props: true,
            name: 'work-orders.bookings',
            component: () =>
              import(/* webpackChunkName: "work-orders.bookings" */ '@/views/work-orders/edit/bookings/bookings.vue')
          },
          {
            path: 'co-hosts',
            props: true,
            name: 'work-orders.co-hosts',
            component: () =>
              import(/* webpackChunkName: "work-orders.co-hosts" */ '@/views/work-orders/edit/co-hosts.vue')
          },
          {
            path: 'conversations',
            props: true,
            name: 'work-orders.conversations',
            component: () =>
              import(/* webpackChunkName: "work-orders.conversations" */ '@/views/work-orders/edit/conversations.vue')
          },
          {
            path: 'intercom-conversations',
            props: true,
            name: 'work-orders.intercom-conversations',
            component: () =>
              import(/* webpackChunkName: "work-orders.intercom-conversations" */ '@/views/work-orders/edit/intercom-conversations/list.vue')
          },
          {
            path: 'intercom-conversations/:conversationId',
            name: 'work-orders.intercom-conversations.show',
            props: true,
            component: () =>
              import(/* webpackChunkName: "work-orders.intercom-conversations.show" */ '@/views/work-orders/edit/intercom-conversations/show.vue')
          },
          {
            path: 'drive',
            props: true,
            name: 'work-orders.drive',
            component: () =>
              import(/* webpackChunkName: "work-orders.drive" */ '@/views/work-orders/edit/drive.vue')
          },
          {
            path: 'map',
            props: true,
            name: 'work-orders.map',
            component: () =>
              import(/* webpackChunkName: "work-orders.map" */ '@/views/work-orders/edit/map.vue')
          },
          {
            path: 'forms',
            props: true,
            name: 'work-orders.forms',
            component: () =>
              import(/* webpackChunkName: "work-orders.forms" */ '@/views/work-orders/edit/forms.vue')
          },
          {
            path: 'goals',
            props: true,
            name: 'work-orders.goals',
            component: () =>
              import(/* webpackChunkName: "work-orders.goals" */ '@/views/work-orders/edit/goals.vue')
          },
          {
            path: 'delegates',
            props: true,
            name: 'work-orders.delegates',
            component: () =>
              import(/* webpackChunkName: "work-orders.delegates" */ '@/views/work-orders/edit/delegates.vue')
          }
        ]
      },
      {
        path: ':workOrderId/forms/:formId',
        props: true,
        name: 'work-orders.form',
        component: () =>
          import(/* webpackChunkName: "work-orders.forms" */ '@/components/work-orders/edit/forms/form.vue')
      },
      {
        path: ':workOrderId/goals/:goalId',
        props: true,
        name: 'work-orders.goal',
        component: () =>
          import(/* webpackChunkName: "work-orders.goal" */ '@/components/work-orders/edit/goals/goal.vue')
      },
      {
        path: ':workOrderId/booking-detail',
        props: true,
        component: () =>
          import(/* webpackChunkName: "work-orders.booking.detail" */ '@/views/work-orders/edit/bookings/base.vue'),
        children: [
          {
            path: 'create',
            props: true,
            name: 'work-orders.bookings.create',
            component: () =>
              import(/* webpackChunkName: "work-orders.bookings.create" */ '@/views/work-orders/edit/bookings/create.vue')
          },
          {
            path: ':bookingId',
            props: true,
            component: () =>
              import(/* webpackChunkName: "work-orders.bookings.edit" */ '@/views/work-orders/edit/bookings/edit.vue'),
            children: [
              {
                path: 'overview',
                name: 'work-orders.bookings.edit.overview',
                props: true,
                component: () =>
                  import(/* webpackChunkName: "work-orders.bookings.edit" */ '@/views/work-orders/edit/bookings/overview.vue')
              },
              {
                path: 'activity',
                name: 'work-orders.bookings.edit.activity',
                props: true,
                component: () =>
                  import(/* webpackChunkName: "work-orders.bookings.edit" */ '@/views/work-orders/edit/bookings/activity.vue')
              }
            ]
          }
        ]
      },
      {
        path: ':workOrderId/requested-bookings',
        props: true,
        component: () =>
          import(/* webpackChunkName: "work-orders.requested-bookings.detail" */ '@/views/work-orders/edit/requested-bookings/base.vue'),
        children: [
          {
            path: ':bookingId/finalize',
            props: true,
            name: 'work-orders.requested-bookings.finalize',
            component: () =>
              import(/* webpackChunkName: "work-orders.requested-bookings.edit" */ '@/views/work-orders/edit/requested-bookings/finalize.vue')
          }
        ]
      }
    ]
  },
  {
    path: '/linkables',
    component: DashboardBase,
    children: [
      {
        path: '',
        name: 'linkables.index',
        props: route => ({ query: route.query.query }),
        component: () =>
          import(/* webpackChunkName: "linkables" */ '@/views/linkables/index.vue')
      },
      {
        path: 'create',
        name: 'linkables.create',
        props: true,
        component: () =>
          import(/* webpackChunkName: "linkables" */ '@/views/linkables/edit.vue')
      },
      {
        path: ':linkableId',
        name: 'linkables.edit',
        props: true,
        component: () =>
          import(/* webpackChunkName: "linkables" */ '@/views/linkables/edit.vue')
      }
    ]
  },
  {
    path: '/links',
    component: DashboardBase,
    children: [
      {
        path: '',
        name: 'links.index',
        props: route => ({ query: route.query.query }),
        component: () =>
          import(/* webpackChunkName: "links" */ '@/views/links/index.vue')
      },
      {
        path: 'create',
        name: 'links.create',
        props: true,
        component: () =>
          import(/* webpackChunkName: "links" */ '@/views/links/edit.vue')
      },
      {
        path: ':linkId',
        name: 'links.edit',
        props: true,
        component: () =>
          import(/* webpackChunkName: "links" */ '@/views/links/edit.vue')
      }
    ]
  },
  {
    path: '/callback',
    name: 'callback',
    component: RelocityAuthCallback,
    props: {
      redirectAfterAuth: '/'
    }
  },
  {
    path: '/login',
    name: 'login'
  }
]

export default routes
