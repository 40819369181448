import http from '@/services/http'

const state = {
}

// getters
const getters = { }

// actions
const actions = {
  async createPolicyOption (c, { policyId, payload }) {
    const { data } = await http.post(`policies/${policyId}/options`, payload)
    return data.data
  },
  async updatePolicyOption ({ commit }, { policyId, policyOptionId, payload }) {
    const { data } = await http.patch(`policies/${policyId}/options/${policyOptionId}`, payload)
    return data.data
  },
  async deletePolicyOption ({ commit }, { policyId, policyOptionId }) {
    const { data } = await http.delete(`policies/${policyId}/options/${policyOptionId}`)
    return data.data
  }
}

// mutations
const mutations = {
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
