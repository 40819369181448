import http from '@/services/http'
import { isEmpty } from '@/utils/lodash'
import { difference } from '@/utils/helpers'

const state = {
  bookings: {},
  booking: {},
  frozenBooking: {}
}

// getters
const getters = {
  hasRequestedBookings: state => !isEmpty(state.bookings),
  bookingChanges: state => difference(state.frozenBooking, state.booking),
  hasChanges: (state, getters) => getters.bookingChanges
}

// actions
const actions = {
  async all ({ commit }, workOrderId) {
    try {
      const { data } = await http.get(`work-orders/${workOrderId}/requested-bookings?filter[exclude]=Booked`)
      commit('SET_WORKORDER_REQUESTED_BOOKINGS', data.data)
      return data.data
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async get ({ commit }, { workOrderId, bookingId }) {
    try {
      const { data } = await http.get(`work-orders/${workOrderId}/requested-bookings/${bookingId}`)
      commit('SET_WORKORDER_REQUESTED_BOOKING', data)
      return data
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async update ({ commit }, { workOrderId, bookingId, ...payload }) {
    try {
      const { data } = await http.post(`work-orders/${workOrderId}/requested-bookings/${bookingId}/booking`, payload)
      return data.data
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async delete ({ commit }, { workOrderId, bookingId }) {
    try {
      const { data } = await http.delete(`work-orders/${workOrderId}/requested-bookings/${bookingId}`)
      return data.data
    } catch (e) {
      return Promise.reject(e)
    }
  },
  clear ({ commit }) {
    commit('CLEAR_WORKORDER_REQUESTED_BOOKINGS')
  }
}

// mutations
const mutations = {
  SET_WORKORDER_REQUESTED_BOOKINGS (state, rows) {
    state.bookings = rows
  },
  CLEAR_WORKORDER_REQUESTED_BOOKINGS (state) {
    state.bookings = {}
  },
  SET_WORKORDER_REQUESTED_BOOKING (state, row) {
    state.booking = row
    state.frozenBooking = Object.freeze(state.booking)
  },
  CLEAR_WORKORDER_REQUESTED_BOOKING (state) {
    state.booking = {}
    state.frozenBooking = {}
  },
  UPDATE_WORKORDER_REQUESTED_BOOKING (state, booking) {
    state.booking = { ...state.booking, ...booking }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
