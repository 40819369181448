import http from '@/services/http'
import { isEmpty, get, upperCase, includes, endsWith } from '@/utils/lodash'
import { SUPER_ADMIN, ADMIN, AREA_OPS_MANAGER, HOST, DISPATCHER } from '@/utils/roles'

const state = {
  currentUser: {},
  employeeProfile: {},
  userCompanies: null,
  hosts: []
}

// getters
const getters = {
  hasUser: state => Object.keys(state.currentUser).length > 0,
  skills: state => get(state.employeeProfile, 'skills'),
  id: state => get(state.currentUser, 'id'),
  role: state => get(state.currentUser, 'role'),
  firstName: state => get(state.currentUser, 'first_name'),
  lastInitial: state => upperCase(get(state.currentUser, 'last_name', '').substring(0, 1)),
  isHostOrAOM: (state, getters) => includes([AREA_OPS_MANAGER, HOST], getters.role.name),
  agentRoles () {
    return 'Agent,Super%20Agent'
  },
  employeeTypes () {
    return [
      { value: 'Full Time', label: 'Full Time' },
      { value: 'Part Time', label: 'Part Time' },
      { value: 'Contract', label: 'Contract' }
    ]
  },
  hasRpnSkill: (state, getters) => includes(getters.skills?.map((s) => s.code) || [], 'S021'),
  isRelocityEmployee: (state, getters) => state.currentUser?.has_employee_profile && endsWith(state.currentUser?.email, '@relocity.com') && includes([SUPER_ADMIN, ADMIN, AREA_OPS_MANAGER, HOST, DISPATCHER], getters.role.name)
}

// actions
const actions = {
  async createUser ({ dispatch }, user) {
    const { data } = await http.post('users', user)
    const result = data.data

    // Associate area if we have it
    if (!isEmpty(user.area)) {
      await dispatch('associateArea', { user: result.id, area: user.area.id })
    }

    // Associate company if we have it
    if (!isEmpty(user.company)) {
      await dispatch('associateCompany', { user: result.id, company: user.company.id })
    }

    if (user.invite === true) {
      dispatch('inviteUser', data.data.id)
    }
    return true
  },
  async getUser ({ commit }, userId) {
    const { data } = await http.get(`users/${userId}`)
    commit('SET_CURRENT_USER', data.data)
  },
  async getEmployeeProfile ({ commit }, userId) {
    const { data } = await http.get(`users/${userId}/employee-profile`)
    commit('SET_USER_EMPLOYEE_PROFILE', data.data)
    return data.data
  },
  async updateEmployeeProfile ({ commit }, { userId, ...payload }) {
    const { data } = await http.patch(`users/${userId}/employee-profile`, payload)
    return data.data
  },
  async updateEmployeeSkills ({ commit }, { userId, ...payload }) {
    const { data } = await http.post(`users/${userId}/employee-profile/skills`, payload)
    return data.data
  },
  async getEmployeeAreas ({ commit }, userId) {
    const { data } = await http.get(`users/${userId}/areas?per_page=all`)
    return data.data
  },
  async detachArea ({ commit }, { userId, id }) {
    const { data } = await http.delete(`users/${userId}/areas/${id}`)
    return data.data
  },
  async associateAreas ({ commit }, { userId, ...payload }) {
    const { data } = await http.post(`users/${userId}/areas`, payload)
    return data.data
  },
  async createPhoneNumber ({ commit }, { userId, ...payload }) {
    const { data } = await http.post(`users/${userId}/phone_numbers`, payload)
    return data.data
  },
  async updatePhoneNumber ({ commit }, { userId, id, ...payload }) {
    const { data } = await http.patch(`users/${userId}/phone_numbers/${id}`, payload)
    return data.data
  },
  async deletePhoneNumber ({ commit }, { userId, id }) {
    const { data } = await http.delete(`users/${userId}/phone_numbers/${id}`)
    return data.data
  },
  async addUserPhoneNumberToWorkOrderConversation ({ commit }, { workOrderId, phoneNumberId }) {
    const { data } = await http.post(`rpn/add-conversation-participant/${workOrderId}/${phoneNumberId}`)
    return data.data
  },
  async updateUser ({ commit }, { userId, payload }) {
    return await http.patch(`users/${userId}`, payload)
  },
  async deleteUser (c, userId) {
    await http.delete(`users/${userId}`)
  },
  async inviteUser (c, userId) {
    await http.post(`users/${userId}/invite`)
  },
  async listUserCompanies ({ commit }, userId) {
    const { data } = await http.get(`users/${userId}/companies?filter=client&per_page=all`)
    commit('SET_USER_COMPANIES', data.data)
  },
  async associateArea ({ commit }, { user, area }) {
    return await http.post(`users/${user}/areas/${area}`)
  },
  async associateCompany ({ commit }, { user, company }) {
    return await http.post(`users/${user}/companies/${company}`)
  },
  async listHosts ({ commit }) {
    const { data } = await http.get('hosts')
    commit('SET_HOSTS', data.data)
  },
  async associatePhoneNumber ({ commit }, { userId, payload }) {
    return await http.post(`users/${userId}/phone_numbers`, payload)
  },
  async getAreaOpsManagers ({ commit }, { areaId }) {
    const { data } = await http.get(`areas/${areaId}/managers`)
    return data.data
  },
  async getAreaHosts ({ commit }, { areaId }) {
    const { data } = await http.get(`areas/${areaId}/hosts?per_page=all`)
    return data.data
  },
  async createAddress ({ commit }, { userId, ...payload }) {
    const { data } = await http.post(`users/${userId}/addresses`, payload)
    return data.data
  },
  async updateAddress ({ commit }, { userId, id, ...payload }) {
    const { data } = await http.patch(`users/${userId}/addresses/${id}`, payload)
    return data.data
  },
  async deleteAddress ({ commit }, { userId, id }) {
    const { data } = await http.delete(`users/${userId}/addresses/${id}`)
    return data.data
  }
}

// mutations
const mutations = {
  SET_CURRENT_USER (state, value) {
    state.currentUser = { ...state.currentUser, ...value }
  },
  CLEAR_CURRENT_USER (state) {
    state.currentUser = {}
    state.employeeProfile = {}
  },
  SET_USER_COMPANIES (state, value) {
    state.userCompanies = value
  },
  SET_USER_EMPLOYEE_PROFILE (state, value) {
    state.employeeProfile = value
  },
  SET_HOSTS (state, value) {
    state.hosts = value
  },
  CLEAR_HOSTS (state) {
    state.hosts = []
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
