import http from '@/services/http'

const state = {
  links: [],
  link: {}
}

// getters
const getters = {
  hasLink (state) {
    return Object.keys(state.link).length > 0
  }
}

// actions
const actions = {
  async createLink ({ commit }, payload) {
    try {
      const { data } = await http.post('links', payload)
      commit('SET_LINK', data.data)
      return data.data
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async getLink ({ commit }, id) {
    const { data } = await http.get(`links/${id}`)
    commit('SET_LINK', data.data)
  },
  async listLink ({ commit }, query) {
    const { data } = await http.get('/links' + (query ? `&query=${query}` : ''))
    commit('SET_LINKS', data.data)
  },
  async updateLink ({ commit }, { id, payload }) {
    const { data } = await http.patch(`links/${id}`, payload)
    commit('SET_LINK', data.data)
  },
  async deleteLink ({ commit }, id) {
    await http.delete(`links/${id}`)
    commit('CLEAR_LINK')
  }
}

// mutations
const mutations = {
  SET_LINKS (state, value) {
    state.links = value
  },
  SET_LINK (state, value) {
    state.link = { ...state.link, ...value }
  },
  CLEAR_LINK (state) {
    state.link = {}
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
