import http from '@/services/http'

const state = {
  workOrderTags: []
}

// getters
const getters = {}

// actions
const actions = {
  async getAvailableWordCloudTags ({ commit }, workOrderId) {
    try {
      const { data } = await http.get(`work-orders/${workOrderId}/tags/word-cloud`)
      return data
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async getWorkOrderTags ({ commit }, workOrderId) {
    try {
      const { data } = await http.get(`work-orders/${workOrderId}/tags`)
      commit('SET_WORK_ORDER_TAGS', data.data)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async saveWorkOrderTags ({ commit }, { workOrderId, payload }) {
    try {
      const { data } = await http.put(`work-orders/${workOrderId}/tags`, payload)
      commit('SET_WORK_ORDER_TAGS', data.data)
    } catch (e) {
      return Promise.reject(e)
    }
  }
}

// mutations
const mutations = {
  CLEAR_WORK_ORDER_TAGS (state) {
    state.workOrderTags = []
  },
  SET_WORK_ORDER_TAGS (state, value) {
    state.workOrderTags = value
  },
  ADD_TEMP_WORK_ORDER_TAG (state, value) {
    state.workOrderTags.push(value)
  }
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
