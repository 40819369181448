import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import duration from 'dayjs/plugin/duration'
import weekday from 'dayjs/plugin/weekday'

dayjs.extend(relativeTime)
dayjs.extend(advancedFormat)
dayjs.extend(utc)
dayjs.extend(localizedFormat)
dayjs.extend(timezone)
dayjs.extend(duration)
dayjs.extend(weekday)

export default dayjs

export function parseDate (date) {
  return dayjs(date)
}

export function format (date, format = 'llll') {
  return dayjs(date, { timeZone: 'UTC' }).format(format)
}

export function formatUTC (date, format = 'llll') {
  return dayjs(date).utc().format(format)
}

export function formatUserTimezone (date, format = 'llll') {
  return dayjs(date).format(format)
}

export function dateMatchesFormat (date, format) {
  return dayjs(date, format, true).isValid()
}

export function formatIso (d) {
  return dayjs(d).format()
}

export function formatUtcIso (d) {
  return dayjs(d).utc().format()
}

export function formatIsoZulu (date) {
  return dayjs(date).format('YYYY-MM-DDTHH:mm:ssZ[Z]')
}

export function relative (date) {
  return dayjs(date).fromNow()
}

export function dateInPast (date) {
  return dayjs(date).isBefore(dayjs())
}

export function daysFromNow (days) {
  return dayjs().add(days, 'days')
}

// get the user timezone
export function browserTimezoneChars () {
  return dayjs.tz.guess()
}

export function todayInTimezone (timezone = 'America/Los_Angeles') {
  return dayjs().tz(timezone)
}

// Returns a relative date if the date is within the last 24 hours, otherwise returns the date and time
export function formatRelativeOrDateTime (date, subtractDuration = '24', subtractUnit = 'hours') {
  const subtractedDate = dayjs().subtract(subtractDuration, subtractUnit)
  if (dayjs(date).isBefore(subtractedDate)) {
    return format(date, 'llll')
  }
  return relative(date)
}

/**
 * Converts a PHP/Carbon modifier expression like "- 3 days" into a string like "3 Days Before"
 * @param expression
 * @returns {*|string}
 */
export const expressionToString = (expression) => {
  if (!expression) {
    return ''
  }

  const regex = /(-\s?\d+|\d+)\s*(days?|weeks?|months?|years?)/i
  const match = expression.match(regex)

  if (!match) {
    return expression
  }

  let [, number, unit] = match
  number = parseInt(number.replace(/\s/g, ''), 10) // Remove any spaces in the number
  unit = unit.toLowerCase()

  // Make the unit singular if the number is 1 or -1
  if (Math.abs(number) === 1) {
    unit = unit.replace(/s$/, '')
  }

  if (number < 0) {
    return `${Math.abs(number)} ${unit.charAt(0).toUpperCase() + unit.slice(1)} Before`
  } else {
    return `${number} ${unit.charAt(0).toUpperCase() + unit.slice(1)} After`
  }
}

export const actionToString = (action) => {
  // eslint-disable-next-line camelcase
  const { schedule_config, execution_condition_config } = action

  // Currently we only have static date, so if there isn't a date return empty string
  const parts = []
  if (schedule_config?.startDateCalculatorConfig?.staticDate) {
    const staticDate = dayjs(schedule_config.startDateCalculatorConfig.staticDate)
    const formattedDate = staticDate.format('MMM D')
    parts.push(`On ${formattedDate}`)
  } else if (schedule_config?.startDateCalculatorConfig) {
    let startDateString = ''
    if (schedule_config.startDateCalculatorConfig.dateModifierExpression) {
      startDateString = expressionToString(schedule_config.startDateCalculatorConfig.dateModifierExpression)
    }
    if (schedule_config.startDateCalculatorConfig.dateLabel) {
      startDateString = `${startDateString} ${schedule_config.startDateCalculatorConfig.dateLabel}`
    }
    parts.push(startDateString)
  }

  // Get the value from execution condition config
  const value = execution_condition_config?.value
  if (value) {
    parts.push(`If value is '${value}'`)
  }

  return parts.filter(Boolean).join(', ')
}
