import http from '@/services/http'

const state = {}

// getters
const getters = {}

// actions
const actions = {
  async get (_, { policyId, code }) {
    const { data } = await http.get(`policies/${policyId}/relocation-benefits/${code}`)
    return data.data
  },
  async sort (_, { policyId, featureId, ids }) {
    const { data } = await http.post(`policies/${policyId}/relocation-benefits/${featureId}/links/order`, { ids })
    return data.data
  },
  async delete (_, { policyId, featureId, linkId }) {
    const { data } = await http.delete(`policies/${policyId}/relocation-benefits/${featureId}/links/${linkId}`)
    return data.data
  },
  async sync (_, { policyId, featureId, ids }) {
    const { data } = await http.post(`policies/${policyId}/relocation-benefits/${featureId}/links/sync`, { ids })
    return data.data
  }
}

// mutations
const mutations = {
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
