import { mapActions, mapGetters } from 'vuex'
import { getConversationAttributes } from '@/services/twilio.conversations'
import { get } from '@/utils/lodash'
import { getMessaging, onMessage, getToken, isSupported } from 'firebase/messaging'

export default {
  data: () => ({
    fcmToken: '',
    conversationClient: null
  }),
  computed: {
    ...mapGetters('auth', ['authUser']),
    isUserLoggedIn () {
      return this.authUser !== null
    }
  },
  watch: {
    fcmToken: {
      handler: 'onFcmTokenChange'
    },
    isUserLoggedIn: {
      handler: 'onUserLoggedInChanged'
    }
  },
  methods: {
    ...mapActions('work-orders/conversations', ['getClient']),
    async onUserLoggedInChanged () {
      if (this.isUserLoggedIn) {
        await this.init()
      }
    },
    async init () {
      try {
        const isSupportedBrowser = await isSupported()

        if (!isSupportedBrowser) {
          console.warn('Browser does not support FCM!')
          return
        }

        const messaging = getMessaging()
        onMessage(messaging, this.onNewMessage)

        this.conversationClient = await this.getClient()
        this.fcmToken = await this.getToken(messaging)
      } catch (error) {
        if (error.code === 'messaging/permission-blocked') {
          console.warn('Push Notifications do not work in Incognito mode.')
        } else {
          console.error(error?.message || 'An error occurred while initializing push notifications')
        }
      }
    },
    onFcmTokenChange () {
      this.conversationClient.setPushRegistrationId('fcm', this.fcmToken)
    },
    async onNewMessage (payload) {
      const attributes = await getConversationAttributes(payload.data.conversation_sid)
      const notification = this.conversationClient.parsePushNotification(payload)

      // Should the notification be displayed?
      const conversationWorkOrderId = get(attributes, 'work_order_id')
      const currentlyViewingWorkOrder = `${conversationWorkOrderId}` === get(this.$route.params, 'workOrderId')
      if (currentlyViewingWorkOrder && this.$route.name === 'work-orders.conversations') {
        return
      }

      const body = `${notification?.body} <br/><a class="underline hover:text-gray-300" target="_blank" href="${process.env.VUE_APP_APP_URL}/work-orders/${conversationWorkOrderId}/conversations">View Conversation</a>`

      this.$notify('primary', {
        title: notification?.title || 'New message',
        html: body
      })
    },
    async getToken (messaging, attempts = 0) {
      return await getToken(messaging, { vapidKey: process.env.VUE_APP_FIREBASE_VAPID_KEY })
    }
  }
}
