import { mapMutations, mapGetters } from 'vuex'
import { debounce, isEmpty, cloneDeep, isEqual } from '@/utils/lodash'
const searchMixin = (updateRoute = true, delay = 500) => ({
  data: () => ({
    searchQueryInput: '',
    routerQueries: {}
  }),
  computed: {
    ...mapGetters('search', { storedQuery: 'query' })
  },
  watch: {
    searchQueryInput: debounce(function (value) {
      this.onSetQuery(value)
    }, delay)
  },
  created () {
    this.routerQueries = cloneDeep(this.$route.query)

    if (!this.searchQueryInput && this.routerQueries?.query) {
      this.searchQueryInput = this.routerQueries.query
    }
  },
  beforeDestroy () {
    this.setQuery('')
  },
  methods: {
    ...mapMutations('search', { setQuery: 'SET_QUERY' }),
    onSetQuery (query) {
      this.updateRoute(query)

      if (query === this.storedQuery) {
        return
      }
      this.setQuery(query.trim())
    },
    updateRoute (value) {
      if (!updateRoute) {
        return
      }
      const query = cloneDeep(this.routerQueries)
      if (!isEmpty(value)) {
        query.query = value
      } else {
        delete query.query
      }
      if (!isEqual(query, this.routerQueries)) {
        this.$router.replace({ name: this.$route.name, query })
      }
    }
  }
})

// fix issue with child being a function and merge requires an options.props object
searchMixin.options = {}

export default searchMixin
