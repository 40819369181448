/* eslint-disable camelcase */
import http from '@/services/http'
import { map, groupBy, first, last, cloneDeep, nth, orderBy } from '@/utils/lodash'
import dayjs from '@/utils/dates'
import twilioClient, { getConversation, getMessages, sendMessage, updateReadHorizon } from '@/services/twilio.conversations'

const state = {
  messages: [],
  participants: []
}
// getters
const getters = {
  messagesGroupedByDay: (state) => {
    const grouped = groupBy(orderBy(state.messages, 'timestamp', ['desc']), message => dayjs(message.timestamp).format('YYYY-MM-DD'))
    const reordered = {}
    Object.keys(grouped).forEach((key) => {
      reordered[key] = orderBy(grouped[key], 'timestamp', ['asc'])
    })
    return reordered
  },
  lastMessage: state => last(state.messages),
  scrollToMessage: state => nth(state.messages, 32), // message to scrol to after loadMoreMessages
  firstMessageInConversation: state => first(state.messages),
  workOrderConversationParticipants: state => state.participants
}
// actions
const actions = {
  async join ({ commit }, { workOrderId, userId }) {
    const { data } = await http.post(`/work-orders/${workOrderId}/users/${userId}/chat/join`)
    return data
  },
  async leave ({ commit }, { workOrderId, userId }) {
    const { data } = await http.delete(`/work-orders/${workOrderId}/users/${userId}/chat/join`)
    return data
  },
  async authenticate ({ commit }, { workOrderId, userId }) {
    const { data: { data: { access_token } } } = await http.get(`/work-orders/${workOrderId}/chat/token`)
    return access_token
  },
  async getClient ({ commit }) {
    const { data: { data: { access_token } } } = await http.get('/users/chat/token')
    return await twilioClient(access_token)
  },
  async initializeConversation ({ commit }, token) {
    return await twilioClient(token)
  },
  async updateReadHorizon ({ commit }, { conversationSid, messageIndex }) {
    try {
      const conversation = await getConversation(conversationSid)
      return await updateReadHorizon(conversation, messageIndex)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async loadMoreMessages ({ commit }, { conversation, messageIndex }) {
    try {
      const messages = await getMessages(conversation, messageIndex)
      commit('PREPEND_CONVERSATIONS_MESSAGES', messages)
      return messages
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async getMessages ({ commit }, conversationSid) {
    try {
      const conversation = await getConversation(conversationSid)
      const messages = await getMessages(conversation)
      commit('SET_CONVERSATIONS_MESSAGES', messages)
      return messages
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async joinAndGetMessages ({ dispatch }, { workOrderId, userId, conversationSid }) {
    try {
      await dispatch('join', { workOrderId, userId })
      const token = await dispatch('authenticate', { workOrderId, userId })
      await dispatch('initializeConversation', token)
      return await dispatch('getMessages', conversationSid)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async sendMessage ({ commit }, { conversationSid, message, attributes }) {
    try {
      const conversation = await getConversation(conversationSid)
      return await sendMessage(conversation, message, attributes)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async sendMedia ({ commit }, { conversationSid, file }) {
    try {
      const conversation = await getConversation(conversationSid)
      const twilioMessage = await sendMessage(conversation, { contentType: file.type, media: file, source: { application_code: 'A001' } })
      return twilioMessage
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async getParticipants ({ commit }, workOrderId) {
    try {
      const { data } = await http.get(`/work-orders/${workOrderId}/work-order-conversation-participants`)
      commit('SET_PARTICIPANTS', data.data)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async createSmsParticipant ({ dispatch }, { workOrderId, participant }) {
    try {
      await http.post(`/rpn/sms-participants/${workOrderId}`, participant)
      await dispatch('getParticipants', workOrderId)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async updateSmsParticipant ({ dispatch }, { workOrderId, participant }) {
    try {
      await http.patch(`/rpn/sms-participants/${participant.id}`, participant)
      await dispatch('getParticipants', workOrderId)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async deleteSmsParticipant ({ dispatch }, { workOrderId, participant }) {
    try {
      await http.delete(`/rpn/sms-participants/${participant.id}`)
      await dispatch('getParticipants', workOrderId)
    } catch (e) {
      return Promise.reject(e)
    }
  }
}

// mutations
const mutations = {
  SET_CONVERSATIONS_MESSAGES (state, messages) {
    state.messages = map(cloneDeep(messages.items), 'state')
  },
  ADD_CONVERSATIONS_MESSAGE (state, message) {
    state.messages.push(message)
  },
  PREPEND_CONVERSATIONS_MESSAGES (state, messages) {
    const pulledMessages = map(cloneDeep(messages.items), 'state')
    state.messages.unshift(...pulledMessages)
  },
  CLEAR_CONVERSATIONS_MESSAGES (state) {
    state.messages = []
  },
  SET_PARTICIPANTS (state, participants) {
    state.participants = participants
  }
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
