import http from '@/services/http'

const state = { }

// getters
const getters = { }

// actions
const actions = {
  async getSsoConfig ({ commit }, params) {
    const { data: { data } } = await http.get(`companies/${params.companyId}/sso-config`)
    return data
  },
  async updateSsoConfig ({ commit }, params) {
    const { data: { data } } = await http.patch(`companies/${params.companyId}/sso-config`, params.payload)
    return data
  }
}

// mutations
const mutations = { }

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
