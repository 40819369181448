import http from '@/services/http'
const state = {
}
// getters
const getters = {
}
// actions
const actions = {
  async getActivity ({ commit }, workOrderId) {
    try {
      const { data } = await http.get(`work-orders/${workOrderId}/activity`, {
        per_page: 'all'
      })
      return data.data
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async retryAction ({ commit }, { workOrderId, actionId }) {
    try {
      const { data } = await http.post(`work-orders/${workOrderId}/actions/${actionId}/retry`)
      return data.data
    } catch (e) {
      return Promise.reject(e)
    }
  }
}
// mutations
const mutations = {
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
