import http from '@/services/http'

const state = {
  policyOption: null
}

// getters
const getters = { }

// actions
const actions = {
  async get ({ commit }, policyOptionId) {
    const { data } = await http.get(`policy-options/${policyOptionId}`)

    commit('SET_POLICY_OPTION', data.data)
  },

  async create ({ commit }, policyOption) {
    const { data } = await http.post('policy-options', policyOption)

    commit('SET_POLICY_OPTION', data.data)

    return data.data
  },

  async update ({ commit }, policyOption) {
    const { data } = await http.patch(`policy-options/${policyOption.id}`, policyOption)

    commit('SET_POLICY_OPTION', data.data)

    return data.data
  },

  async destroy ({ commit }, policyOptionId) {
    await http.delete(`policy-options/${policyOptionId}`)
  },

  async restore ({ commit }, policyOptionId) {
    const { data } = await http.patch(`policy-options/${policyOptionId}/restore`)

    commit('SET_POLICY_OPTION', data.data)

    return data.data
  },

  async duplicate ({ commit }, { id, name }) {
    const { data } = await http.post(`policy-options/${id}/duplicate`, { name })

    commit('SET_POLICY_OPTION', data.data)

    return data.data
  }
}

// mutations
const mutations = {
  SET_POLICY_OPTION (state, value) {
    state.policyOption = { ...state.policyOption, ...value }
  },

  CLEAR_POLICY_OPTION (state) {
    state.policyOption = null
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
