const { Client } = require('@twilio/conversations')

let client = null

const initClient = async token => {
  try {
    client = new Client(token)
    return client
  } catch (e) {
    return Promise.reject(e)
  }
}

const getConversation = async conversationSid => {
  try {
    return await client.getConversationBySid(conversationSid)
  } catch (e) {
    return Promise.reject(e)
  }
}

// pageSize default is 30
// getMessages() accepts anchor as undefiend or integer
const getMessages = async (conversation, messageAnchor) => {
  try {
    return await conversation.getMessages(15, messageAnchor)
  } catch (e) {
    return Promise.reject(e)
  }
}

const updateReadHorizon = async (conversation, messageIndex) => {
  try {
    return await conversation.updateLastReadMessageIndex(messageIndex)
  } catch (e) {
    return Promise.reject(e)
  }
}

const getConversationAttributes = async conversationId => {
  try {
    const conv = await client.getConversationBySid(conversationId)
    return await conv.getAttributes()
  } catch (e) {
    return Promise.reject(e)
  }
}

const sendMessage = async (conversation, message, attributes = {}) => {
  try {
    const newMessage = await conversation.sendMessage(message, attributes)
    return await conversation.updateLastReadMessageIndex(newMessage)
  } catch (e) {
    return Promise.reject(e)
  }
}

export { client, getConversation, getMessages, getConversationAttributes, sendMessage, updateReadHorizon }
export default initClient
