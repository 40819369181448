import http from '@/services/http'
import { isEmpty, omit, get } from '@/utils/lodash'
import { difference } from '@/utils/helpers'

const state = {
  booking: {},
  frozenBooking: {}
}

// getters
const getters = {
  id: state => get(state.booking, 'id'),
  bookingChanges: state => difference(state.frozenBooking, state.booking),
  hasChanges: (state, getters) => !isEmpty(getters.id > 0 ? getters.bookingChanges : omit(getters.bookingChanges, ['notify_attendees', 'block_time']))
}

// actions
const actions = {
  async get ({ commit }, { workOrderId, bookingId }) {
    try {
      const { data } = await http.get(`work-orders/${workOrderId}/bookings/${bookingId}`)
      commit('SET_WORKORDER_BOOKING', data.data)
      return data.data
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async delete ({ commit }, { workOrderId, bookingId }) {
    try {
      const { data } = await http.delete(`work-orders/${workOrderId}/bookings/${bookingId}`)
      return data.data
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async create ({ commit }, { workOrderId, ...payload }) {
    try {
      const { data } = await http.post(`work-orders/${workOrderId}/bookings`, payload)
      return data.data
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async update ({ commit }, { workOrderId, bookingId, ...payload }) {
    try {
      const { data } = await http.patch(`work-orders/${workOrderId}/bookings/${bookingId}`, payload)
      return data.data
    } catch (e) {
      return Promise.reject(e)
    }
  },
  clear ({ commit }) {
    commit('CLEAR_WORKORDER_BOOKING')
  }
}

// mutations
const mutations = {
  SET_WORKORDER_BOOKING (state, row) {
    state.booking = row
    state.frozenBooking = Object.freeze(state.booking)
  },
  CLEAR_WORKORDER_BOOKING (state) {
    state.booking = {}
    state.frozenBooking = {}
  },
  UPDATE_WORKORDER_BOOKING (state, booking) {
    state.booking = { ...state.booking, ...booking }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
