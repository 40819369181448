import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import auth from '@relocity/auth'
import PortalVue from 'portal-vue'
import VueMq from 'vue-mq'
import Permissions from './plugins/permissions'
import VueScrollTo from 'vue-scrollto'
import VueAnalytics from 'vue-analytics'
import VueGtag from 'vue-gtag'
import VueIntercom from '@mathieustan/vue-intercom'

import './registerServiceWorker'
import '@/services/events'
import '@/services/echo'
import '@/services/firebase'
import '@/components/ui.js'
import '@/css/styles.css'

Vue.config.productionTip = false

Vue.use(auth, {
  appUrl: process.env.VUE_APP_APP_URL,
  ssoUrl: process.env.VUE_APP_API_BASE,
  callbackUrl: process.env.VUE_APP_CALLBACK_URL,
  clientId: process.env.VUE_APP_CLIENT_ID,
  scopes: process.env.VUE_APP_SCOPES,
  skipOauthLogout: true
})

Vue.use(PortalVue)

Vue.use(VueScrollTo)

Vue.use(VueMq, {
  breakpoints: {
    xs: 0,
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280
  }
})

Vue.use(VueAnalytics, {
  id: process.env.VUE_APP_GOOGLE_ANALYTICS_TAG,
  debug: {
    sendHitTask: process.env.NODE_ENV === 'production'
  }
})

Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_GOOGLE_GTAG }
})

Vue.use(Permissions, {
  store
})

Vue.use(VueIntercom, {
  api_base: 'https://api-iam.intercom.io',
  appId: process.env.VUE_APP_INTERCOM_APP_ID
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
