import http from '@/services/http'

const state = {}

// getters
const getters = {}

// actions
const actions = {
  async saveAllocatedHour ({ commit }, { workOrderId, allocatedHour }) {
    try {
      const { data } = await http.post(`work-orders/${workOrderId}/allocated-times`, allocatedHour)
      return data.data
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async updateAllocatedHour ({ commit }, { workOrderId, allocatedHour }) {
    try {
      const { data } = await http.patch(`work-orders/${workOrderId}/allocated-times/${allocatedHour.id}`, allocatedHour)
      return data.data
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async deleteAllocatedHour ({ commit }, { workOrderId, allocatedHour }) {
    try {
      await http.delete(`work-orders/${workOrderId}/allocated-times/${allocatedHour.id}`, allocatedHour)
    } catch (e) {
      return Promise.reject(e)
    }
  }
}
// mutations
const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
