import http from '@/services/http'

const state = {
  policyFeatures: []
}

const getters = { }

const actions = {
  async all ({ commit }) {
    const { data } = await http.get('policy-features')

    commit('SET_POLICY_FEATURES', data.data)
  }
}

const mutations = {
  SET_POLICY_FEATURES (state, value) {
    state.policyFeatures = value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
