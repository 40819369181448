import http from '@/services/http'
const state = {
  session: {}
}
// getters
const getters = {
}
// actions
const actions = {
  async createSession ({ commit }, { payload }) {
    try {
      const { data } = await http.post('checkout/create-session', payload)
      commit('SET_SESSION_ID', data.session_id)
      return data.session_id
    } catch (e) {
      return Promise.reject(e)
    }
  }
}
// mutations
const mutations = {
  CLEAR_SESSION_ID (state) {
    state.session.id = null
  },
  SET_SESSION_ID (state, value) {
    state.session.id = value
  }
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
