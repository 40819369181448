import http from '@/services/http'
import { includes, get, map, endsWith } from '@/utils/lodash'
import { ADMIN, SUPER_ADMIN, AREA_OPS_MANAGER, HOST, DISPATCHER, SUPER_AGENT, AGENT } from '@/utils/roles'

const state = {
  token: localStorage.getItem('relocity_access_token'),
  authUser: null
}

// getters
const getters = {
  token: state => state.token,
  authUser: state => state.authUser,
  userId: state => get(state.authUser, 'id'),
  isSuperAdmin: (state, getters) => includes([SUPER_ADMIN], getters.role),
  isSuperAgent: (state, getters) => includes([SUPER_AGENT], getters.role),
  isAgent: (state, getters) => includes([AGENT], getters.role),
  isAdmin: (state, getters) => includes([SUPER_ADMIN, ADMIN], getters.role),
  isAdminOrAOM: (state, getters) => includes([SUPER_ADMIN, ADMIN, AREA_OPS_MANAGER], getters.role),
  isDispatcher: (state, getters) => getters.role === DISPATCHER,
  isHost: (state, getters) => getters.role === HOST,
  isHostOrAOM: (state, getters) => includes([HOST, AREA_OPS_MANAGER], getters.role),
  isRelocityEmployee: (state, getters) => state.authUser?.has_employee_profile && endsWith(state.authUser?.email, '@relocity.com') && includes([SUPER_ADMIN, ADMIN, AREA_OPS_MANAGER, HOST, DISPATCHER], getters.role),
  lastNameInitial: state =>
    state.authUser ? `${state.authUser.last_name.charAt(0)}.` : null,
  shortName: (state, getters) =>
    state.authUser
      ? `${state.authUser.first_name} ${getters.lastNameInitial}`
      : null,
  role: state => (state.authUser ? state.authUser.role.name : null),
  skills: state => state.authUser?.employeeProfile?.skills || [],
  skillCodes: (state, getters) => map(getters.skills, 'code'),
  hasSkill: (state, getters) => skill => includes(getters.skillCodes, skill),
  intercomProfile: (state) =>
    state.authUser
      ? {
          user_id: state.authUser.id,
          name: `${state.authUser.first_name} ${state.authUser.last_name}`,
          email: state.authUser.email,
          role: state.authUser.role.name,
          relocity_employee: state.authUser.has_employee_profile,
          company: {
            id: state.authUser.company?.id,
            name: state.authUser.company?.name
          },
          avatar: {
            type: 'avatar',
            image_url: state.authUser.avatar_url
          },
          user_hash: state.authUser?.intercom_user_hashes?.web,
          created_at: state.authUser.created_at
        }
      : null
}

// actions
const actions = {
  async getProfile ({ getters, commit, dispatch }) {
    const authUser = localStorage.getItem('authUser')
    if (authUser && authUser.length > 0) {
      commit('SET_AUTH_USER', JSON.parse(authUser))
      return
    }
    const { data } = await http.get('users/profile')
    commit('SET_AUTH_USER', data.data)

    if (getters.isRelocityEmployee) {
      await dispatch('getEmployeeProfile')
    }
  },
  async getEmployeeProfile ({ state, commit }) {
    const { data } = await http.get(`users/${state.authUser.id}/employee-profile`)
    commit('SET_AUTH_USER_EMPLOYEE_PROFILE', data.data)
    return data.data
  },
  setAvatar ({ commit }, value) {
    commit('SET_AVATAR', value)
  },
  async deleteAvatar ({ state, commit }) {
    const { data } = await http.delete('/users/' + state.authUser.id + '/avatar')

    const avatar = data.data.avatar
    commit('SET_AVATAR', avatar)
  },
  async updateProfile ({ commit }, { userId, payload }) {
    const { data } = await http.patch(`users/${userId}`, payload)
    commit('SET_AUTH_USER', data.data)
  }
}

// mutations
const mutations = {
  SET_AUTH_TOKEN (state, value) {
    state.token = value
    localStorage.setItem('relocity_access_token', value)
  },
  SET_AUTH_USER (state, value) {
    if (state.authUser === null) {
      state.authUser = value
    } else {
      state.authUser = { ...state.authUser, ...value }
    }
    localStorage.setItem('authUser', JSON.stringify(state.authUser))
  },
  SET_AVATAR (state, value) {
    state.authUser.avatar = value
  },
  SET_AUTH_USER_EMPLOYEE_PROFILE (state, employeeProfile) {
    state.authUser.employeeProfile = employeeProfile
    localStorage.setItem('authUser', JSON.stringify(state.authUser))
  },
  CLEAR_AUTH_USER (state) {
    localStorage.setItem('authUser', null)
    state.authUser = null
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
