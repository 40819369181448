import http from '@/services/http'

const state = {
  addresses: [],
  meta: {
    current_page: 1,
    last_page: 1,
    per_page: 10,
    total: 0
  }
}

const getters = {
  addresses: state => state.addresses,
  meta: state => state.meta
}

const actions = {
  async listCompanyAddresses ({ commit }, { companyId, page = 1 }) {
    try {
      const { data } = await http.get(`/companies/${companyId}/addresses`, { params: { page } })
      commit('SET_ADDRESSES', data.data)
      commit('SET_PAGINATION_META', data.meta)
    } catch (error) {
      console.error('Failed to fetch addresses:', error)
    }
  },
  async deleteCompanyAddress ({ dispatch }, { companyId, addressId }) {
    try {
      await http.delete(`/companies/${companyId}/addresses/${addressId}`)
      await dispatch('listCompanyAddresses', { companyId })
    } catch (error) {
      console.error('Failed to delete address:', error)
    }
  }
}

const mutations = {
  SET_ADDRESSES (state, addresses) {
    state.addresses = addresses
  },
  SET_PAGINATION_META (state, meta) {
    state.meta = meta
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
