
const state = {
  state: null,
  error: null
}

// getters
const getters = {}

// actions
const actions = {}

// mutations
const mutations = {
  PUSHER_SET_STATE (state, status) {
    state.state = status
  },
  PUSHER_SET_ERROR (state, error) {
    state.error = error
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
