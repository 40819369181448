import http from '@/services/http'

const state = {
  roles: null
}

// getters
const getters = { }

// actions
const actions = {
  async listRoles ({ commit }) {
    const { data: { data } } = await http.get('roles')
    commit('SET_ROLES', data)
  }
}

// mutations
const mutations = {
  SET_ROLES (state, value) {
    state.roles = value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
