import http from '@/services/http'
import { find } from '@/utils/lodash'

const state = {
  genders: [],
  maritalStatus: []
}

// getters
const getters = {
  gendersList: (state) =>
    Object.entries(state.genders).map(([key, value]) => ({
      id: key,
      label: value
    })),
  getGender: (state, getters) => id => find(getters.gendersList, { id }),
  maritalStatusList: (state) =>
    Object.entries(state.maritalStatus).map(([key, value]) => ({
      id: key,
      label: value
    })),
  getMaritalStatusById: (state, getters) => id => find(getters.maritalStatusList, { id })
}

// actions
const actions = {
  async getGenders ({ commit }) {
    const { data } = await http.get('/client-options/gender')
    commit('SET_GENDERS', data.data)
  },
  async getMaritalStatus ({ commit }) {
    const { data } = await http.get('/client-options/marital-status')
    commit('SET_MARITAL_STATUS', data.data)
  }
}

// mutations
const mutations = {
  SET_GENDERS (state, value) {
    state.genders = value
  },
  SET_MARITAL_STATUS (state, value) {
    state.maritalStatus = value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
