import http from '@/services/http'

const state = {
  linkables: [],
  linkable: {},
  linkableCategories: [],
  linkableTypes: []
}

// getters
const getters = {
  hasLinkable (state) {
    return Object.keys(state.linkable).length > 0
  }
}

// actions
const actions = {
  async createLinkable ({ commit }, payload) {
    try {
      const { data } = await http.post('linkables', payload)
      commit('SET_LINKABLE', data.data)
      return data.data
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async getLinkable ({ commit }, id) {
    const { data } = await http.get(`linkables/${id}`)
    commit('SET_LINKABLE', data.data)
  },
  async listLinkable ({ commit }, query) {
    const { data } = await http.get('/linkables' + (query ? `&query=${query}` : ''))
    commit('SET_LINKABLES', data.data)
  },
  async updateLinkable ({ commit }, { id, payload }) {
    const { data } = await http.patch(`linkables/${id}`, payload)
    commit('SET_LINKABLE', data.data)
  },
  async deleteLinkable ({ commit }, id) {
    await http.delete(`linkables/${id}`)
    commit('CLEAR_LINKABLE')
  },
  async listLinkableCategories ({ commit }) {
    const { data } = await http.get('linkable-categories')
    commit('SET_LINKABLE_CATEGORIES', data.data)
  },
  async listLinkableTypes ({ commit }) {
    const { data } = await http.get('linkable-types')
    commit('SET_LINKABLE_TYPES', data.data)
  }
}

// mutations
const mutations = {
  SET_LINKABLES (state, value) {
    state.linkables = value
  },
  SET_LINKABLE (state, value) {
    state.linkable = { ...state.linkable, ...value }
  },
  CLEAR_LINKABLE (state) {
    state.linkable = {}
  },
  SET_LINKABLE_CATEGORIES (state, value) {
    state.linkableCategories = value
  },
  SET_LINKABLE_TYPES (state, value) {
    state.linkableTypes = value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
