export const FIXED_GROUP = 'fixed'
export const SCROLLABLE_GROUP = 'scrollable'

export const COMPONENT_TYPE_STATIC = 'static'
export const COMPONENT_TYPE_LINKS = 'links'

export const COMPONENT_SIZE_OPTIONS = ['full', 'half', 'quarter']
export const COMPONENT_HEIGHT_OPTIONS = ['small', 'medium', 'large']

export const DESTINATION_AND_WEATHER = 'destination_and_weather'
export const SHOPPING_CART = 'shopping_cart'
export const SPACER = 'spacer'
export const ANNOUNCEMENTS = 'announcements'
export const GOALS = 'goals'
export const DOCUMENTS = 'documents'
export const SERVICE_CATEGORIES = 'service_categories'
export const RELOCATION_BENEFIT_SERVICE_CATEGORIES = 'relocation_benefit_service_categories'
export const HORIZONTAL_LINKS = 'horizontal_links'
export const VERTICAL_LINKS = 'vertical_links'
export const GRID_LINKS = 'grid_links'
export const VIDEO_CAROUSEL = 'video_carousel'
export const NEXT_STEPS = 'next_steps'

export const componentPropertiesMap = new Map([
  [DESTINATION_AND_WEATHER, {
    icon: 'cloud-sun',
    hasContent: false
  }],
  [SHOPPING_CART, {
    icon: 'shopping-cart',
    hasContent: false
  }],
  [SPACER, {
    icon: 'shelves-empty',
    hasContent: false
  }],
  [ANNOUNCEMENTS, {
    icon: 'bullhorn',
    hasContent: false
  }],
  [GOALS, {
    icon: 'bullseye'
  }],
  [DOCUMENTS, {
    icon: 'file'
  }],
  [SERVICE_CATEGORIES, {
    icon: 'list',
    hasContent: false
  }],
  [RELOCATION_BENEFIT_SERVICE_CATEGORIES, {
    icon: 'list-alt',
    hasContent: false
  }],
  [HORIZONTAL_LINKS, {
    icon: 'external-link-alt'
  }],
  [VERTICAL_LINKS, {
    icon: 'external-link-alt'
  }],
  [GRID_LINKS, {
    icon: 'th'
  }],
  [VIDEO_CAROUSEL, {
    icon: 'play-circle'
  }],
  [NEXT_STEPS, {
    icon: 'bullseye'
  }]
])
