import http from '@/services/http'

const state = {
  goals: [],
  goal: null,
  showSections: false,
  showBuilder: true,
  showPreview: true,
  editingPublishedGoal: false
}

// getters
const getters = { }

// actions
const actions = {
  async createGoal (c, payload) {
    try {
      const { data } = await await http.post('goals', payload)
      return data.data
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async getGoal ({ commit }, { goalId, include }) {
    try {
      const { data } = await http.get(`goals/${goalId}`, { params: { include } })
      commit('SET_GOAL', data.data)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async listGoals ({ commit }) {
    try {
      const { data } = await http.get('goals?per_page=all')
      commit('SET_GOALS', data.data)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async updateGoal ({ commit }, { goalId, payload }) {
    try {
      payload.include = 'sections'
      const { data } = await http.patch(`goals/${goalId}`, payload)
      commit('SET_GOAL', data.data)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async deleteGoal (c, { goalId }) {
    try {
      await http.delete(`goals/${goalId}`)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async getGoalVersions (c, { group }) {
    try {
      const { data } = await http.get(`goals/versions/${group}`)
      return data.data
    } catch (e) {
      return Promise.reject(e)
    }
  }
}

// mutations
const mutations = {
  SET_GOAL (state, value) {
    state.goal = value
  },
  SET_GOALS (state, value) {
    state.goals = value
  },
  // goal tasks builder
  SET_SHOW_BUILDER (state, value) {
    state.showBuilder = value
  },
  SET_SHOW_PREVIEW (state, value) {
    state.showPreview = value
  },
  SET_SHOW_SECTIONS (state, value) {
    state.showSections = value
  },
  // edit published goal
  SET_EDITING_PUBLISHED_GOAL (state, value) {
    state.editingPublishedGoal = value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
