<template>
  <autocomplete
    ref="autocomplete"
    input-class="form__input"
    v-bind="$attrs"
    :source="source"
    :results-property="property"
    :results-display="display"
    :initial-value="initial"
    :initial-display="initial"
    :results-value="value"
    @clear="onClear"
    @close="onClose"
    @enter="onEnter"
    @results="onResults"
    @noResults="onNoResults"
    @selected="onSelected"
    @input="onInput"
  />
</template>

<script>
import Autocomplete from 'vuejs-auto-complete'

export default {
  components: {
    Autocomplete
  },
  props: {
    source: {
      type: [String, Function, Object, Array],
      required: true
    },
    initial: {
      type: String,
      default: ''
    },
    property: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: true
    },
    display: {
      type: [String, Function],
      required: true
    }
  },
  methods: {
    clear () {
      this.$refs.autocomplete.clear()
    },
    onClear () {
      this.$emit('clear')
    },
    onClose () {
      this.$emit('close')
    },
    onEnter (value) {
      this.$emit('enter', value)
    },
    onResults (value) {
      this.$emit('results', value)
    },
    onNoResults (value) {
      this.$emit('results', value)
    },
    onSelected (value) {
      this.$emit('selected', value)
    },
    onInput (value) {
      this.$emit('input', value)
    }
  }
}
</script>
