import http from '@/services/http'
import { find } from '@/utils/lodash'

const state = {
  editingSectionItem: {},
  fieldTypes: [],
  taskConnections: [],
  dueDateCalculators: []
}

// getters
const getters = {
  getFieldType: state => type => find(state.fieldTypes, (value, key) => type === value.field_type),
  getDueDateCalculatorList: state => {
    return [{ value: null, label: 'None' }, ...state.dueDateCalculators]
  }
}

// actions
const actions = {
  async createGoalSectionItem (c, { goalId, sectionId, payload }) {
    const { data } = await http.post(`goals/${goalId}/sections/${sectionId}/items`, payload)

    return data.data
  },
  async deleteGoalSectionItem ({ commit }, { goalId, sectionId, sectionItemId }) {
    const { data } = await http.delete(`goals/${goalId}/sections/${sectionId}/items/${sectionItemId}`)
    return data.data
  },
  async sortGoalSectionItems (c, { goalId, sectionId, payload }) {
    const { data } = await http.post(`goals/${goalId}/sections/${sectionId}/items/order`, payload)
    return data.data
  },
  async updateGoalSectionItem ({ commit }, { goalId, sectionId, sectionItemId, payload }) {
    const { data } = await http.patch(`goals/${goalId}/sections/${sectionId}/items/${sectionItemId}`, payload)
    commit('SET_EDITING_SECTION_ITEM', data.data)
  },
  async listFieldTypes ({ commit }) {
    const { data } = await http.get('goal-builder/fields')
    commit('SET_FIELD_TYPES', data.data)
  },
  async listTaskConnections ({ commit }) {
    const { data } = await http.get('goal-builder/task-connections')
    commit('SET_TASK_CONNECTIONS', data.data)
  },
  async listDueDateCalculators ({ commit }) {
    const { data } = await http.get('goals/tasks/due-date-calculators')
    commit('SET_DUE_DATE_CALCULATORS', data.data)
  }
}

// mutations
const mutations = {
  CLEAR_EDITING_SECTION_ITEM (state) {
    state.editingSectionItem = {}
  },
  SET_EDITING_SECTION_ITEM (state, value) {
    state.editingSectionItem = { ...state.editingSectionItem, ...value }
  },
  SET_FIELD_TYPES (state, value) {
    state.fieldTypes = value
  },
  SET_TASK_CONNECTIONS (state, value) {
    state.taskConnections = value
  },
  SET_DUE_DATE_CALCULATORS (state, value) {
    state.dueDateCalculators = value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
