import http from '@/services/http'

const state = {}

// getters
const getters = {}

// actions
const actions = {
  async upload (c, { userId, file }) {
    try {
      const formData = new FormData()
      formData.append('file', file)
      const { data } = await http.post(`users/${userId}/avatar`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      return data.data
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async delete (c, userId) {
    try {
      const { data } = await http.delete(`users/${userId}/avatar`)
      return data.data
    } catch (e) {
      return Promise.reject(e)
    }
  }
}

// mutations
const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
