import http from '@/services/http'

const state = {
  properties: {}
}

const getters = {
  injectableProperties: state => model => state.properties[model] || []
}

const actions = {
  async list ({ commit }, model) {
    try {
      const { data } = await http.get(`injectable-properties/${model}`)
      commit('SET_PROPERTIES', { model, properties: data })
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

const mutations = {
  SET_PROPERTIES (state, { model, properties }) {
    state.properties = {
      ...state.properties,
      [model]: properties
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
