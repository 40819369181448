import http from '@/services/http'

const state = {
}

// getters
const getters = { }

// actions
const actions = {
  async import (_, { id, ...form }) {
    try {
      return await http.post(`companies/${id}/addresses/import`, form)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async edit (_, { companyId, addressId, params }) {
    try {
      await http.patch(`/companies/${companyId}/addresses/${addressId}`, params)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async add (_, { companyId, params }) {
    try {
      await http.post(`/companies/${companyId}/addresses`, params)
    } catch (e) {
      return Promise.reject(e)
    }
  }
}

// mutations
const mutations = {
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
