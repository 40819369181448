<script>
export default {
  methods: {
    onSubmit () {
      this.$emit('submit')
    }
  }
}
</script>

<template>
  <form @submit.prevent="onSubmit">
    <slot />
  </form>
</template>
