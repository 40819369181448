import http from '@/services/http'
const state = {
  pointOfInterestCategories: []
}
// getters
const getters = {}
// actions
const actions = {
  async list ({ commit }, perPage = 15) {
    try {
      const { data } = await http.get(`poi-categories?per_page=${perPage}`)
      commit('SET_POINT_OF_INTEREST_CATEGORIES', data.data)
    } catch (e) {
      return Promise.reject(e)
    }
  }
}
// mutations
const mutations = {
  SET_POINT_OF_INTEREST_CATEGORIES (state, value) {
    state.pointOfInterestCategories = value
  }
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
