<template>
  <div class="md:hidden">
    <div
      class="fixed inset-0 flex z-40"
      :class="{ hidden: !open }"
    >
      <transition
        enter-active-class="transition-opacity ease-linear duration-300"
        enter-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="transition-opacity ease-linear duration-300"
        leave-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          v-if="open"
          class="fixed inset-0"
        >
          <div class="absolute inset-0 bg-gray-600 opacity-75" />
        </div>
      </transition>
      <transition
        enter-active-class="transition ease-in-out duration-300 transform"
        enter-class="-translate-x-full"
        enter-to-class="translate-x-0"
        leave-active-class="transition ease-in-out duration-300 transform"
        leave-class="translate-x-0"
        leave-to-class="-translate-x-full"
      >
        <div
          v-if="open"
          class="relative flex-1 flex flex-col max-w-xs w-full bg-primary"
        >
          <div class="absolute top-0 right-0 -mr-14 p-1">
            <button
              class="flex items-center justify-center h-12 w-12 rounded-full focus:outline-none focus:bg-gray-600"
              aria-label="Close sidebar"
              @click="onClose"
            >
              <r-icon
                name="times"
                variant="white"
                size="lg"
              />
            </button>
          </div>
          <div class="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
            <div class="flex-shrink-0 flex items-center px-4">
              <logo />
            </div>
            <navigation class="mt-5 px-2 space-y-1" />
          </div>
          <navigation-avatar />
        </div>
      </transition>
      <div class="flex-shrink-0 w-14">
        <!-- Force sidebar to shrink to fit close icon -->
      </div>
    </div>
  </div>
</template>

<script>
import Logo from '@/components/layout/header/logo.vue'
import Navigation from '@/components/layout/navigation/list.vue'
import NavigationAvatar from '@/components/layout/navigation/avatar.vue'
export default {
  components: {
    Logo,
    Navigation,
    NavigationAvatar
  },
  props: {
    open: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onClose () {
      this.$emit('close')
    }
  }
}
</script>
