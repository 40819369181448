import http from '@/services/http'

const state = {
  items: []
}

// getters
const getters = {
}

// actions
const actions = {
  addItem ({ commit }, item) {
    commit('ADD_ITEM', item)
  },
  async getItems ({ commit }, workOrderId) {
    try {
      const { data } = await http.get(`work-orders/${workOrderId}/drive`)
      commit('SET_ITEMS', data.data)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async deleteItem ({ commit }, { workOrderId, fileId }) {
    try {
      await http.delete(`work-orders/${workOrderId}/drive/${fileId}`)
      commit('REMOVE_ITEM', fileId)
    } catch (e) {
      return Promise.reject(e)
    }
  }
}

// mutations
const mutations = {
  ADD_ITEM (state, item) {
    state.items.push(item)
  },
  SET_ITEMS (state, value) {
    state.items = value
  },
  REMOVE_ITEM (state, value) {
    state.items = state.items.filter(item => item.id !== value)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
