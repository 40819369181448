import http from '@/services/http'

const state = {
  topic: null
}

// getters
const getters = {}

// actions
const actions = {
  async create (c, payload) {
    try {
      const { data } = await await http.post('topics', payload)
      return data.data
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async get ({ commit }, topicId) {
    try {
      const { data } = await http.get(`topics/${topicId}`)
      commit('SET_TOPIC', data.data)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async update ({ commit }, { topicId, payload }) {
    try {
      const { data } = await http.patch(`topics/${topicId}`, payload)
      commit('SET_TOPIC', data.data)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async restore (c, topicId) {
    await http.post(`topics/${topicId}/restore`)
  },
  async delete (c, topicId) {
    try {
      await http.delete(`topics/${topicId}`)
    } catch (e) {
      return Promise.reject(e)
    }
  }
}

// mutations
const mutations = {
  SET_TOPIC (state, value) {
    state.topic = value
  },
  CLEAR_TOPIC (state) {
    state.topic = {}
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
