import http from '@/services/http'

const state = {
  bookingDetails: {},
  workOrder: {}
}

// getters
const getters = { }

// actions
const actions = {
  async getBookingDetails ({ commit }, { bookingId, workOrderId }) {
    commit('CLEAR_BOOKING_DETAILS')
    const { data } = await http.get(`work-orders/${workOrderId}/bookings/${bookingId}`)
    commit('SET_BOOKING_DETAILS', data.data)
  },
  async getWorkOrder ({ commit }, workOrderId) {
    const { data } = await http.get(`work-orders/${workOrderId}`)
    commit('SET_WORK_ORDER', data.data)
  }
}

// mutations
const mutations = {
  SET_BOOKING_DETAILS (state, value) {
    state.bookingDetails = value
  },
  CLEAR_BOOKING_DETAILS (state) {
    state.bookingDetails = {}
  },
  SET_WORK_ORDER (state, value) {
    state.workOrder = value
  },
  CLEAR_WORK_ORDER (state) {
    state.workOrder = {}
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
