import http from '@/services/http'

const state = {
  timeZones: null
}

// getters
const getters = { }

// actions
const actions = {
  async listTimeZones ({ commit }) {
    const { data } = await http.get('time-zones')
    commit('SET_TIME_ZONES', data)
  }
}

// mutations
const mutations = {
  SET_TIME_ZONES (state, value) {
    state.timeZones = value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
