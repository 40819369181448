import Pusher from 'pusher-js'
import Echo from 'laravel-echo'
import store from '../store'

const client = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY, {
  cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
  authEndpoint: process.env.VUE_APP_API_BASE + '/broadcasting/auth',
  auth: {
    headers: {
      Authorization: 'Bearer ' + localStorage.relocity_access_token
    }
  },
  encrypted: true
})

const echo = new Echo({ broadcaster: 'pusher', client })

echo.connector.pusher.connection.bind('error', error =>
  store.commit('pusher/PUSHER_SET_ERROR', error))

echo.connector.pusher.connection.bind('state_change', ({ current }) => store.commit('pusher/PUSHER_SET_STATE', current))

export default echo
