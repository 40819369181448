<template>
  <div class="h-screen flex overflow-hidden bg-white">
    <!-- Off-canvas menu for mobile -->
    <mobile-menu
      :open="showMobileMenu"
      @close="hideMobileMenu"
    />

    <!-- Static sidebar for desktop -->
    <desktop-menu :full="fullSidebarNavigation" />

    <div class="flex flex-col w-0 flex-1 overflow-hidden">
      <div
        v-show="showTopBar"
        class="relative z-10 flex-shrink-0 flex h-16 bg-white shadow"
      >
        <mobile-menu-toggle @change="showMobileMenuMutation" />
        <navigation-bar v-if="showSearchBar" />
      </div>

      <main
        class="flex-1 relative overflow-y-auto focus:outline-none relocity-bg"
        tabindex="0"
      >
        <div class="hidden max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <h1 class="text-2xl font-semibold text-gray-900">
            Dashboard
          </h1>
        </div>
        <div class="w-full h-auto mx-auto">
          <slot />
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import MobileMenu from '@/components/layout/mobile/menu.vue'
import DesktopMenu from '@/components/layout/desktop/menu.vue'
import MobileMenuToggle from '@/components/layout/mobile/toggle.vue'
import NavigationBar from '@/components/layout/header/bar.vue'
import { mapGetters, mapMutations, mapState } from 'vuex'
export default {
  components: {
    MobileMenu,
    DesktopMenu,
    NavigationBar,
    MobileMenuToggle
  },
  computed: {
    ...mapGetters('layout', ['fullSidebarNavigation', 'showSearchBar']),
    ...mapState('layout', ['showMobileMenu']),
    showTopBar () {
      return ['xs', 'sm', 'md'].includes(this.$mq) || this.showSearchBar
    }
  },
  methods: {
    ...mapMutations('layout', {
      hideMobileMenu: 'HIDE_MOBILE_MENU',
      showMobileMenuMutation: 'SHOW_MOBILE_MENU'
    })
  }
}
</script>
