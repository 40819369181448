import http from '@/services/http'
import { isEmpty, get, size, join, find, filter, includes, map } from '@/utils/lodash'

const state = {
  workOrders: [],
  workOrder: {}
}
// getters
const getters = {
  broadcastChannel: state => get(state.workOrder, 'broadcast_channel'),
  hasBroadcastChannel: (state, getters) => !isEmpty(getters.broadcastChannel),
  hasWorkOrder: state => !isEmpty(state.workOrder),
  workOrderId: state => get(state.workOrder, 'id'),
  conversationSid: state => get(state.workOrder, 'channel_uid'),
  client: state => get(state.workOrder, 'client'),
  referenceNumber: state => get(state.workOrder, 'reference_number'),
  clientName: (state, getters) => join([get(getters.client, 'first_name'), get(getters.client, 'last_name')], ' '),
  host: state => get(state.workOrder, 'host'),
  hasClient: (state, getters) => !isEmpty(getters.client),
  hasHost: (state, getters) => !isEmpty(getters.host),
  bookings: state => get(state.workOrder, 'bookings'),
  hasBookings: (state, getters) => !isEmpty(getters.bookings),
  requestedBookings: state => filter(get(state.workOrder, 'requested_bookings'), booking => booking.state !== 'booked'),
  hasRequestedBookings: (state, getters) => !isEmpty(getters.requestedBookings),
  totalBookings: (state, getters) => size(getters.bookings) + size(getters.requestedBookings),
  hasAnyBookings: (state, getters) => getters.hasBookings || getters.hasRequestedBookings,
  policy: (state, getters) => state.workOrder.policy,
  policyFeatures: (state, getters) => getters.policy?.features,
  hasFeature: (state, getters) => code => includes(map(getters.policyFeatures, 'code'), code),
  addresses: state => get(state.workOrder, 'addresses'),
  permanentHouseAddress: (state, getters) => find(getters.addresses, { type: 'permanent-house' }),
  state: state => get(state.workOrder, 'state'),
  canHaveEvents: (_, getters) => !includes(['new', 'processing'], getters.state.toLowerCase()),
  isOneProgram: (state, getters) => getters.policy?.program?.code === 'P003',
  addons: state => get(state.workOrder, 'addOns'),
  conciergeAddon: (state, getters) => find(getters.addons, addon => addon?.program?.code === 'P001'),
  hasAddons: (state, getters) => size(getters.policy?.addOns) > 0
}
// actions
const actions = {
  async createWorkOrder ({ commit }, { payload }) {
    try {
      const { data } = await http.post('work-orders/', payload)
      commit('SET_WORK_ORDER', data.data)
      return data.data
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async deleteWorkOrder ({ commit }, { workOrderId }) {
    try {
      return await http.delete(`work-orders/${workOrderId}`)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async getWorkOrder ({ commit }, workOrderId) {
    try {
      const { data } = await http.get(`work-orders/${workOrderId}`)
      commit('SET_WORK_ORDER', data.data)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async listWorkOrders ({ commit }) {
    try {
      const { data } = await http.get('work-orders/')
      commit('SET_WORK_ORDERS', data.data)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async updateWorkOrder ({ commit }, { workOrderId, payload }) {
    try {
      const { data } = await http.patch(`work-orders/${workOrderId}`, payload)
      commit('SET_WORK_ORDER', data.data)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  viewWorkOrder ({ commit }, { workOrderId }) {
    window.location.href = `${process.env.VUE_APP_DASHBOARD_URL}/work-orders/${workOrderId}/overview`
  },
  async getBookings (c, id) {
    const { data } = await http.get(`work-orders/${id}/bookings?per_page=all`)

    return data.data
  },
  async toggleStage ({ commit }, { workOrderId, toggleBool }) {
    try {
      const { data } = await http.post(`work-orders/${workOrderId}/open/${toggleBool}`)
      commit('SET_WORK_ORDER', data.data)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async deletePermanentHouseAddress (_, workOrderId) {
    try {
      await http.delete(`work-orders/${workOrderId}/permanent-home-address`)
    } catch (e) {
      return Promise.reject(e)
    }
  }
}
// mutations
const mutations = {
  CLEAR_WORK_ORDER (state) {
    state.workOrder = {}
  },
  SET_WORK_ORDERS (state, value) {
    state.workOrders = value
  },
  SET_WORK_ORDER (state, value) {
    state.workOrder = { ...state.workOrder, ...value }
  }
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
