import http from '@/services/http'

const state = {
}

// getters
const getters = {
}

// actions
const actions = {
  async all ({ commit }, workOrderId) {
    try {
      const { data } = await http.get(`work-orders/${workOrderId}/available-services`)
      return data.data
    } catch (e) {
      return Promise.reject(e)
    }
  }
}

// mutations
const mutations = {
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
