import http from '@/services/http'

const state = {
  conditionProperties: []
}

// getters
const getters = {}

// actions
const actions = {
  async getGroupedConditions ({ commit }, { goalId }) {
    const { data } = await http.get(`goals/${goalId}/conditionals`)
    return data.data
  },
  async getConditionProperties ({ commit }) {
    const { data } = await http.get('goal-conditional-properties')
    commit('SET_CONDITION_PROPERTIES', data.data)
  },
  async createCondition ({ commit }, { goalId, params }) {
    const { data } = await http.post(`goals/${goalId}/conditionals`, params)
    return data.data
  },
  async updateCondition ({ commit }, { goalId, conditionId, params }) {
    const { data } = await http.patch(`goals/${goalId}/conditionals/${conditionId}`, params)
    return data.data
  },
  async deleteCondition ({ commit }, { goalId, conditionId }) {
    const { data } = await http.delete(`goals/${goalId}/conditionals/${conditionId}`)
    return data.data
  },
  async saveConditionOrder (c, { goalId, params }) {
    const { data } = await http.post(`goals/${goalId}/conditionals/order`, params)
    return data
  }
}

// mutations
const mutations = {
  SET_CONDITION_PROPERTIES (state, value) {
    state.conditionProperties = value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
