import http from '@/services/http'

const state = {
}

// getters
const getters = {
}

// actions
const actions = {
  async store (_, { workOrderId, delegate }) {
    try {
      const { data } = await http.post(`work-orders/${workOrderId}/delegates`, delegate)
      return data.data
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async update (_, { workOrderId, delegateId, payload }) {
    try {
      const { data } = await http.patch(`work-orders/${workOrderId}/delegates/${delegateId}`, payload)
      return data.data
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async delete (_, { workOrderId, delegateId }) {
    try {
      return await http.delete(`work-orders/${workOrderId}/delegates/${delegateId}`)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async reSendInvite (_, { workOrderId, delegateId }) {
    try {
      return await http.post(`work-orders/${workOrderId}/delegates/${delegateId}/invite`)
    } catch (e) {
      return Promise.reject(e)
    }
  }
}

// mutations
const mutations = {
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
