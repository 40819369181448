<template>
  <div
    class="p-2"
    :class="{ short }"
  >
    <!-- My Profile / Logout Menu -->
    <transition
      enter-class="opacity-0"
      enter-active-class="transition-opacity ease-linear duration-100"
      enter-to-class="opacity-100"
      leave-class="opacity-100"
      leave-active-class="transition-opacity ease-linear duration-100"
      leave-to-class="opacity-0"
    >
      <div
        v-if="open"
        class="mt-5 flex-1 bg-white rounded-md overflow-hidden"
      >
        <nav :class="{ short }">
          <a
            class="cursor-pointer"
            @click.prevent="onMyProfileClick"
          >
            <r-icon
              variant
              icon-style="fad"
              name="id-card"
            />
            <span>My Profile</span>
          </a>
        </nav>
        <nav :class="{ short }">
          <a
            href="#logout"
            @click.prevent="onLogout"
          >
            <r-icon
              variant=""
              icon-style="fad"
              name="sign-out-alt"
            />
            <span>Log Out</span>
          </a>
        </nav>
      </div>
    </transition>

    <!-- Avatar -->
    <div class="flex-shrink-0 flex bg-primary p-2">
      <div class="flex-shrink-0 group block">
        <r-button
          class="flex items-center pl-0 text-left if-short-btn"
          variant=""
          aria-haspopup="true"
          narrow
          @click="onToggle"
        >
          <r-avatar
            v-if="authUser && authUser.avatar"
            :size="size"
            round="full"
            :source="authUser.avatar"
          />
          <div v-if="authUser && name">
            <p class="text-sm leading-5 font-medium text-white whitespace-no-wrap">
              {{ shortName }}
            </p>
            <p class="text-xs leading-4 font-medium text-gray-300 whitespace-no-wrap">
              {{ authUser.role.name }}
            </p>
          </div>
        </r-button>
      </div>
    </div>
  </div>
</template>

<script>
import { logout } from 'utils/helpers'
import { mapGetters } from 'vuex'
export default {
  props: {
    name: {
      type: Boolean,
      default: true
    },
    size: {
      type: String,
      default: 'md'
    },
    short: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    open: false
  }),
  computed: {
    ...mapGetters('auth', ['authUser', 'shortName', 'userId']),
    canNavigateToMyProfile () {
      return (
        this.$route.name !== 'users.edit' ||
        (this.$route.name === 'users.edit' &&
          this.$route.params.userId !== this.userId)
      )
    }
  },
  methods: {
    onToggle () {
      this.open = !this.open
    },
    onLogout () {
      this.open = false
      logout()
    },
    onMyProfileClick () {
      if (this.canNavigateToMyProfile) {
        this.$router.push({
          name: 'users.edit',
          params: { userId: this.userId }
        })
      }
      this.open = false
    }
  }
}
</script>

<style lang="scss" scoped>
button {
  min-width: 75px;
}
nav {
  a {
    @apply group flex items-center px-2 py-2 text-primary leading-6 font-medium text-white focus:outline-none focus:text-primary focus:bg-white transition ease-in-out duration-150 whitespace-no-wrap;

    &:hover {
      @apply text-primary bg-gray-200;
    }

    i {
      @apply px-2;
    }
  }
}

.avatar {
  @apply border border-white shadow-md mr-3;
}

.short {
  nav {
    a {
      span {
        @apply w-full text-left px-0;
      }

      i {
        @apply hidden;
      }
    }
  }

  .if-short-btn {
    @apply px-0 mx-0;
  }

  .avatar {
    @apply m-auto;
  }
}
</style>
