import http from '@/services/http'

const state = {
  properties: {}
}

const getters = {
  variables: state => {
    return Object.keys(state.properties).filter((variable) => {
      const value = state.properties[variable]
      return (value === null) ? false : typeof value === 'number' || value.length > 0
    })
  },
  allVariables: state => {
    return Object.keys(state.properties)
  }
}

const actions = {
  async list ({ commit }, { params }) {
    try {
      const { data } = await http.get('templatable-text', { params })
      commit('SET_PROPERTIES', data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

const mutations = {
  SET_PROPERTIES (state, value) {
    state.properties = value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
