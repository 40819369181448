import http from '@/services/http'

const state = {}

// getters
const getters = {}

// actions
const actions = {
  async prompt (_, prompt) {
    try {
      const { data } = await http.post('copywriter/prompt', { prompt })
      return data
    } catch (e) {
      return Promise.reject(e)
    }
  }
}

// mutations
const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
