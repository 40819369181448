import http from '@/services/http'

const state = {
  transactions: []
}

const getters = { }

const actions = {
  async index ({ commit }, { workOrderId }) {
    const { data } = await http.get(`work-orders/${workOrderId}/transactions`)

    commit('SET_TRANSACTIONS', data.data)

    return data.data
  },

  async store ({ commit }, { workOrderId, ...payload }) {
    const { data } = await http.post(`work-orders/${workOrderId}/transactions`, payload)

    return data.data
  },

  async update ({ commit }, { workOrderId, id, ...payload }) {
    const { data } = await http.patch(`work-orders/${workOrderId}/transactions/${id}`, payload)

    return data.data
  },

  async destroy ({ commit }, { workOrderId, id, ...payload }) {
    const { data } = await http.delete(`work-orders/${workOrderId}/transactions/${id}`, payload)

    return data.data
  }
}

const mutations = {
  SET_TRANSACTIONS (state, value) {
    state.transactions = value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
