import http from '@/services/http'

const state = {
  policyAddOns: [],
  availableAddOns: []
}

// getters
const getters = { }

// actions
const actions = {
  async getPolicyAddOns ({ commit }, { policyId }) {
    const { data } = await http.get(`policies/${policyId}/add-ons`)

    commit('SET_POLICY_ADD_ONS', data.data)

    return data.data
  },

  async getPolicyAddOnsForWorkOrder ({ commit }, { workOrderId, policyId }) {
    const { data } = await http.get(`work-orders/${workOrderId}/policies/${policyId}/add-ons`)

    commit('SET_AVAILABLE_ADD_ONS', data.data)

    return data.data
  }
}

// mutations
const mutations = {
  SET_POLICY_ADD_ONS (state, value) {
    state.policyAddOns = value
  },

  SET_AVAILABLE_ADD_ONS (state, value) {
    state.availableAddOns = value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
