import http from '@/services/http'

const state = {
  applications: []
}

// getters
const getters = { }

// actions
const actions = {
  async getApplication ({ commit }, params) {
    const { data: { data } } = await http.get(`companies/${params.companyId}/applications/${params.applicationId}`)
    commit('SET_APPLICATIONS', data)
    return data
  },
  async saveApplication ({ commit }, params) {
    const { data: { data } } = await http.post(`companies/${params.companyId}/applications`, params.payload)
    return data
  },
  async updateApplication ({ commit }, params) {
    const { data: { data } } = await http.put(`companies/${params.companyId}/applications/${params.applicationId}`, params.payload)
    return data
  },
  async deleteApplication ({ commit }, params) {
    const { data: { data } } = await http.delete(`companies/${params.companyId}/applications/${params.applicationId}`)
    return data
  }
}

// mutations
const mutations = {
  SET_APPLICATIONS (state, payload) {
    state.applications = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
