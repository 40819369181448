import config from './config'

const defaultOptions = {}

export default {

  install (Vue, userOptions) {
    const options = { ...defaultOptions, ...userOptions }

    function canDo (route, method) {
      const authUser = options.store.getters['auth/authUser']
      if (!authUser) {
        // don't allow them to go.  Something is wrong
        console.warn('authUser is not defined')
        return false
      }
      const configMethod = config[route][method]
      if (!configMethod) {
        return false
      }
      return configMethod[0] === '*' || configMethod.includes(authUser.role.name)
    }

    Vue.$can = canDo
    Vue.prototype.$can = canDo

    function isReadOnly (route, field) {
      const authUser = options.store.getters['auth/authUser']
      if (!authUser) {
        console.warn('authUser is not defined')
        // set to read only because something is wrong
        return true
      }
      const role = authUser.role.name
      const res = config[route]
      if (!res.readOnlyFields || !res.readOnlyFields[role]) {
        // if there are no read only fields or no read only fields for that role, it's not read only
        return false
      }
      const fields = res.readOnlyFields[role]
      return fields.includes(field)
    }

    Vue.prototype.$isReadOnly = isReadOnly
  }
}
