import http from '@/services/http'

// actions
const actions = {
  async updatePolicyTab (c, { id: tabId, ...params }) {
    const { data } = await http.patch(`policy-tabs/${tabId}`, params)
    return data.data
  }
}

export default {
  namespaced: true,
  actions
}
