import http from '@/services/http'
const state = {
  serviceCategoryTags: [],
  prohibitedServiceCategoryTags: []
}
// getters
const getters = {}
// actions
const actions = {
  async list ({ commit }) {
    try {
      const { data } = await http.get('service-categories/tags')
      commit('SET_SERVICE_CATEGORY_TAGS', data.data)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async listProhibited ({ commit }, companyId) {
    const { data } = await http.get(`/companies/${companyId}/prohibited-service-category-tags`)
    commit('SET_PROHIBITED_SERVICE_CATEGORY_TAGS', data.data)
  },
  async syncProhibited (c, { companyId, ids }) {
    const { data } = await http.put(`/companies/${companyId}/prohibited-service-category-tags/`, { ids })
    return data.data
  }
}
// mutations
const mutations = {
  SET_SERVICE_CATEGORY_TAGS (state, value) {
    state.serviceCategoryTags = value
  },
  SET_PROHIBITED_SERVICE_CATEGORY_TAGS (state, value) {
    state.prohibitedServiceCategoryTags = value
  }
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
