import http from '@/services/http'

const state = {}

const getters = {}

const actions = {
  async store ({ commit }, { workOrderId, policyId }) {
    await http.post(`work-orders/${workOrderId}/add-ons/${policyId}`)
  },

  async update ({ commit }, { workOrderId, policyId, payload }) {
    await http.patch(`work-orders/${workOrderId}/add-ons/${policyId}`, payload)
  }
}

const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
