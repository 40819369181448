import http from '@/services/http'

const state = { }

// getters
const getters = { }

// actions
const actions = {
  async getAttributes ({ commit }, applicationId) {
    const { data: { data } } = await http.get(`company-applications/${applicationId}/attributes`)
    return data
  },
  async saveAttribute ({ commit }, params) {
    const { data: { data } } = await http.post(`company-applications/${params.applicationId}/attributes`, params.payload)
    return data
  },
  async updateAttribute ({ commit }, params) {
    const { data: { data } } = await http.put(`company-applications/${params.applicationId}/attributes/${params.attributeId}`, params.payload)
    return data
  },
  async deleteAttribute ({ commit }, params) {
    const { data: { data } } = await http.delete(`company-applications/${params.applicationId}/attributes/${params.attributeId}`)
    return data
  }
}

// mutations
const mutations = { }

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
