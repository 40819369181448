import http from '@/services/http'
import Vue from 'vue'

const SET_ACTIONS = 'SET_ACTIONS'
const ADD_ACTION = 'ADD_ACTION'
const UPDATE_ACTION = 'UPDATE_ACTION'
const DELETE_ACTION = 'DELETE_ACTION'
const SET_ACTION_HANDLERS = 'SET_ACTION_HANDLERS'

const state = {
  actionGroups: {}, // { actionGroup: { actionableId: [actions] } }
  actionHandlers: {}
}

// getters
const getters = {
  // Get all actions for a specific actionGroup and actionableId
  getActions: (state) => (actionGroup, actionableId) => {
    return (state.actionGroups[actionGroup]?.[actionableId]) || []
  },

  // Get a specific action by its ID within an actionGroup and actionableId
  getActionById: (state) => ({ actionGroup, actionableId, actionId }) => {
    const actions = state.actionGroups[actionGroup]?.[actionableId] || []
    return actions.find(action => action.id === actionId)
  },

  getActionHandlers: (state) => (actionGroup) => state.actionHandlers[actionGroup] ?? [],

  getActionHandlerForAction: (state) => (actionGroup, action) => {
    return state.actionHandlers[actionGroup]?.find((actionHandler) => action.general_action_handler_id === actionHandler.id)
  }
}

// actions
const actions = {
  async listActions ({ commit }, { actionGroup, actionableId }) {
    const { data } = await http.get('general-actions', {
      params: {
        action_group: actionGroup,
        actionable_id: actionableId
      }
    })
    commit(SET_ACTIONS, { actionGroup, actionableId, actions: data.data })
  },

  async createAction ({ commit }, { actionGroup, payload }) {
    const { data } = await http.post('general-actions', payload)
    const actionableId = data.data.actionable_id
    commit(ADD_ACTION, { actionGroup, actionableId, action: data.data })
  },

  async updateAction ({ commit, state }, { actionGroup, action, payload }) {
    const { data } = await http.patch(`general-actions/${action.id}`, payload)

    const actionableId = action.actionable_id
    const actions = state.actionGroups[actionGroup]?.[actionableId] || []
    const index = actions.findIndex(r => r.id === action.id)

    if (index !== -1) {
      commit(UPDATE_ACTION, { actionGroup, actionableId, index, action: data.data })
    }
  },

  async deleteAction ({ commit, state }, { actionGroup, action }) {
    await http.delete(`general-actions/${action.id}`)

    const actionableId = action.actionable_id
    const actions = state.actionGroups[actionGroup]?.[actionableId] || []
    const index = actions.findIndex(r => r.id === action.id)

    if (index !== -1) {
      commit(DELETE_ACTION, { actionGroup, actionableId, index })
    }
  },

  async listActionHandlers ({ commit }, { actionGroup }) {
    const { data } = await http.get('general-action-handlers', {
      params: {
        filter: {
          action_group: actionGroup
        }
      }
    })
    commit(SET_ACTION_HANDLERS, { actionGroup, actionHandlers: data.data })
  },

  async getConfigFields ({ commit }, { generalActionHandlerId, payload }) {
    const { data } = await http.post(`general-action-handlers/${generalActionHandlerId}/configure`, payload)
    return data.data
  }
}

// mutations
const mutations = {
  [SET_ACTIONS] (state, { actionGroup, actionableId, actions }) {
    if (!state.actionGroups[actionGroup]) {
      // Ensure that the actionGroup exists in a reactive way
      Vue.set(state.actionGroups, actionGroup, {})
    }

    // Ensure that the actionableId is added reactively under the actionGroup
    Vue.set(state.actionGroups[actionGroup], actionableId, actions)
  },

  [ADD_ACTION] (state, { actionGroup, actionableId, action }) {
    if (!state.actionGroups[actionGroup]) {
      Vue.set(state.actionGroups, actionGroup, {})
    }
    if (!state.actionGroups[actionGroup][actionableId]) {
      Vue.set(state.actionGroups[actionGroup], actionableId, [])
    }
    state.actionGroups[actionGroup][actionableId].push(action)
  },

  [UPDATE_ACTION] (state, { actionGroup, actionableId, index, action }) {
    if (state.actionGroups[actionGroup]?.[actionableId]?.[index]) {
      state.actionGroups[actionGroup][actionableId].splice(index, 1, action)
    }
  },

  [DELETE_ACTION] (state, { actionGroup, actionableId, index }) {
    if (state.actionGroups[actionGroup]?.[actionableId]) {
      state.actionGroups[actionGroup][actionableId].splice(index, 1)
    }
  },

  [SET_ACTION_HANDLERS] (state, { actionGroup, actionHandlers }) {
    Vue.set(state.actionHandlers, actionGroup, actionHandlers)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
