import http from '@/services/http'

const state = {
  allPolicies: [],
  allActivePolicies: [],
  associatedCompanies: [],
  policies: [],
  policy: {},
  prohibitedServiceProviderPages: []
}

// getters
const getters = {
  isCurrentPolicyLocked: state => state.policy?.locked
}

// actions
const actions = {
  async attachAssociatedCompany (c, { policyId, companyId, ...params }) {
    await http.post(`policies/${policyId}/companies/${companyId}/attach`, params)
  },
  async attachPolicyFeature (c, { policyId, featureId }) {
    const { data } = await http.post(`policies/${policyId}/features/${featureId}`)
    return data.data
  },
  async attachPolicyPackage (c, { policyId, packageId }) {
    const { data } = await http.post(`policies/${policyId}/packages/${packageId}`)
    return data.data
  },
  async createPolicy (c, policy) {
    const { data } = await http.post('policies', policy)
    return data.data
  },
  async detachAssociatedCompany (c, { policyId, companyId }) {
    await http.delete(`policies/${policyId}/companies/${companyId}/detach`)
  },
  async detachPolicyFeature (c, { policyId, featureId }) {
    const { data } = await http.delete(`/policies/${policyId}/features/${featureId}`)
    return data.data
  },
  async detachPolicyPackage (c, { policyId, packageId }) {
    const { data } = await http.delete(`/policies/${policyId}/packages/${packageId}`)
    return data.data
  },
  async detachPolicyForm (c, { policyId, id }) {
    const { data } = await http.delete(`/policies/${policyId}/forms/${id}/detach`)
    return data.data
  },
  async detachPolicyGoal (c, { policyId, id }) {
    const { data } = await http.delete(`/policies/${policyId}/goals/${id}/detach`)
    return data.data
  },
  async detachPolicyOption (c, { policyId, optionId }) {
    const { data } = await http.delete(`/policies/${policyId}/options/${optionId}`)
    return data.data
  },
  async detachProhibitedServiceProviderPages (c, { policyId, id }) {
    const { data } = await http.delete(`/policies/${policyId}/prohibited-service-provider-pages/${id}`)
    return data.data
  },
  async deletePolicy (c, policyId) {
    await http.delete(`policies/${policyId}`)
  },
  async duplicatePolicy (c, { policyId, name }) {
    const { data } = await http.post(`policies/${policyId}/duplicate`, { name })
    return data.data
  },
  async togglePolicy (_, policyId) {
    const { data } = await http.post(`policies/${policyId}/toggle`)
    return data.data
  },
  async getPolicy ({ commit }, policyId) {
    const { data } = await http.get(`policies/${policyId}?include=policyFeatures`)
    commit('SET_POLICY', data.data)
  },
  async listAssociatedCompanies ({ commit }, policyId) {
    const { data } = await http.get(`policies/${policyId}/companies`)
    commit('SET_ASSOCIATED_COMPANIES', data.data)
    return data.data
  },
  async listAllPolicies ({ commit }) {
    const { data } = await http.get('policies?per_page=all&filter[trashed]=with')
    commit('SET_ALL_POLICIES', data.data)
  },
  async listAllActivePolicies ({ commit }) {
    const { data } = await http.get('policies?per_page=all&filter[trashed]=active')
    commit('SET_ALL_ACTIVE_POLICIES', data.data)
  },
  async listMigrationJobs (c) {
    const { data } = await http.get('policy-migration-jobs')
    return data.data
  },
  async listPolicies ({ commit }) {
    const { data } = await http.get('policies')
    commit('SET_POLICIES', data.data)
  },
  async listPolicyFeatures (c, policyId) {
    const { data } = await http.get(`policies/${policyId}/features?per_page=all`)
    return data.data
  },
  async listPolicyPackages (c, policyId) {
    const { data } = await http.get(`policies/${policyId}/packages?per_page=all`)
    return data.data
  },
  async listPolicyForms (c, policyId) {
    const { data } = await http.get(`policies/${policyId}/forms?per_page=all`)
    return data.data
  },
  async listPolicyGoals (c, policyId) {
    const { data } = await http.get(`policies/${policyId}/goals?per_page=all`)
    return data.data
  },
  async listPolicyOptions (c, policyId) {
    const { data } = await http.get(`policies/${policyId}/options?per_page=all`)
    return data.data
  },
  async listPolicyAddOns (c, policyId) {
    const { data } = await http.get(`policies/${policyId}/add-ons?per_page=all`)
    return data.data
  },
  async listSections (c, tabId) {
    const { data } = await http.get(`policy-tabs/${tabId}/sections`)
    return data.data
  },
  async listPolicyTags (c, policyId) {
    const { data } = await http.get(`policies/${policyId}/tags`)
    return data.data
  },
  async savePolicyTags (c, { policyId, payload }) {
    const { data } = await http.put(`/policies/${policyId}/tags/`, payload)
    return data.data
  },
  async listProhibitedPointOfInterestCategories (c, policyId) {
    const { data } = await http.get(`policies/${policyId}/prohibited-point-of-interest-categories`)
    return data.data
  },
  async listProhibitedServiceCategories (c, policyId) {
    const { data } = await http.get(`policies/${policyId}/prohibited-service-categories`)
    return data.data
  },
  async listProhibitedServiceCategoryTags (c, policyId) {
    const { data } = await http.get(`policies/${policyId}/prohibited-service-category-tags`)
    return data.data
  },
  async listProhibitedServiceProviderPages ({ commit }, policyId) {
    const { data } = await http.get(`/policies/${policyId}/prohibited-service-provider-pages`)
    commit('SET_PROHIBITED_SERVICE_PROVIDER_PAGES', data.data)
  },
  async restorePolicy (c, policyId) {
    await http.post(`policies/${policyId}/restore`)
  },
  async syncPolicyForms (c, { policyId, ids }) {
    const { data } = await http.post(`/policies/${policyId}/forms/sync`, { ids })
    return data.data
  },
  async syncPolicyGoals (c, { policyId, ids }) {
    const { data } = await http.post(`/policies/${policyId}/goals/sync`, { ids })
    return data.data
  },
  async syncPolicyFeatures (c, { policyId, featureIds }) {
    const { data } = await http.post(`/policies/${policyId}/features/sync`, { ids: featureIds })
    return data.data
  },
  async syncPolicyFeatureProvider ({ commit }, { policyId, featureId, ...policyFeatureProvider }) {
    const { data } = await http.post(`/policies/${policyId}/features/${featureId}/providers/sync`, policyFeatureProvider)
    return data
  },
  async syncPolicyPackages (c, { policyId, packageIds }) {
    const { data } = await http.post(`/policies/${policyId}/packages/sync`, { ids: packageIds })
    return data.data
  },
  async syncPolicyOptions (c, { policyId, optionIds }) {
    const { data } = await http.post(`/policies/${policyId}/options/sync`, { ids: optionIds })
    return data.data
  },
  async syncProhibitedPointOfInterestCategories (c, { policyId, ids }) {
    const { data } = await http.put(`/policies/${policyId}/prohibited-point-of-interest-categories/`, { ids })
    return data.data
  },
  async syncProhibitedServiceCategories (c, { policyId, ids }) {
    const { data } = await http.put(`/policies/${policyId}/prohibited-service-categories/`, { ids })
    return data.data
  },
  async syncProhibitedServiceCategoryTags (c, { policyId, ids }) {
    const { data } = await http.put(`/policies/${policyId}/prohibited-service-category-tags/`, { ids })
    return data.data
  },
  async syncProhibitedServiceProviderPages (c, { policyId, ids }) {
    const { data } = await http.put(`/policies/${policyId}/prohibited-service-provider-pages`, { ids })
    return data.data
  },
  async updatePolicy ({ commit }, { policyId, payload }) {
    const { data } = await http.patch(`policies/${policyId}`, payload)
    commit('SET_POLICY', data.data)
  },
  async updatePolicyGoalOrder (c, { policyId, groups }) {
    const { data } = await http.post(`/policies/${policyId}/goals/order`, { groups })
    return data.data
  },
  async listPolicyTopics (c, policyId) {
    const { data } = await http.get(`topics?per_page=all&filter[policies.id]=${policyId}`)
    return data.data
  },
  async syncPolicyTopics (c, { policyId, ids }) {
    const { data } = await http.post(`/policies/${policyId}/topics/sync`, { ids })
    return data.data
  },
  async detachPolicyTopic (c, { policyId, id }) {
    const { data } = await http.delete(`/policies/${policyId}/topics/${id}/detach`)
    return data.data
  },
  async listPolicyValidations (c, policyId) {
    const { data } = await http.get(`/policies/${policyId}/work-order-validations`)
    return data.data
  },
  async syncPolicyValidations (c, { policyId, ids }) {
    const { data } = await http.post(`/policies/${policyId}/work-order-validations/sync`, { ids })
    return data.data
  },
  async updatePolicyValidationOrder (c, { policyId, ids }) {
    const { data } = await http.post(`/policies/${policyId}/work-order-validations/order`, { ids })
    return data.data
  },
  async detachPolicyValidation (c, { policyId, id }) {
    const { data } = await http.delete(`/policies/${policyId}/work-order-validations/${id}`)
    return data.data
  }
}

// mutations
const mutations = {
  CLEAR_POLICY (state) {
    state.policy = {}
  },
  SET_ALL_POLICIES (state, value) {
    state.allPolicies = value
  },
  SET_ALL_ACTIVE_POLICIES (state, value) {
    state.allActivePolicies = value
  },
  SET_ASSOCIATED_COMPANIES (state, value) {
    state.associatedCompanies = value
  },
  SET_POLICIES (state, value) {
    state.policies = value
  },
  SET_POLICY (state, value) {
    state.policy = { ...state.policy, ...value }
  },
  SET_PROHIBITED_SERVICE_PROVIDER_PAGES (state, value) {
    state.prohibitedServiceProviderPages = value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
