import http from '@/services/http'
const state = {
  programs: []
}
// getters
const getters = {
}
// actions
const actions = {
  async list ({ commit }) {
    try {
      const { data } = await http.get('programs')
      commit('SET_PROGRAMS', data.data)
    } catch (e) {
      return Promise.reject(e)
    }
  }
}
// mutations
const mutations = {
  SET_PROGRAMS (state, value) {
    state.programs = value
  }
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
