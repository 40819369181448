<template>
  <r-button
    class="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:bg-gray-100 focus:text-gray-600 md:hidden"
    aria-label="Open sidebar"
    variant=""
    @click="onToggle"
  >
    <svg
      class="h-6 w-6"
      stroke="currentColor"
      fill="none"
      viewBox="0 0 24 24"
    >

      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M4 6h16M4 12h16M4 18h7"
      />
    </svg>
  </r-button>
</template>

<script>
export default {
  methods: {
    onToggle () {
      this.$emit('change')
    }
  }
}
</script>
