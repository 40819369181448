import http from '@/services/http'
import { get } from '@/utils/lodash'

const state = {
  dashboardData: {}
}

// getters
const getters = {
  startDate: (state, getters, rootState) => get(rootState, 'route.query.startDate'),
  endDate: (state, getters, rootState) => get(rootState, 'route.query.endDate')
}

// actions
const actions = {
  async getDashboardData ({ commit }, { startDate, endDate }) {
    commit('CLEAR_DASHBOARD_DATA')
    const { data } = await http.get(`/metrics?start_date=${startDate}&end_date=${endDate}`)
    commit('SET_DASHBOARD_DATA', data)
  }
}

// mutations
const mutations = {
  CLEAR_DASHBOARD_DATA (state) {
    state.dashboardData = {}
  },
  SET_DASHBOARD_DATA (state, value) {
    state.dashboardData = value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
