import http from '@/services/http'

const state = {
  actions: []
}

// getters
const getters = { }

// actions
const actions = {
  async detachAction (c, { policyId, actionId }) {
    await http.post(`policies/${policyId}/actions/${actionId}/detach`)
  },
  async listActions ({ commit }, policyId) {
    const { data } = await http.get(`policies/${policyId}/actions?per_page=all`)
    commit('SET_ACTIONS', data.data)
  },
  async saveActionOrder (c, { policyId, payload }) {
    await http.post(`policies/${policyId}/actions/order`, payload)
  },
  async syncActions (c, { policyId, payload }) {
    await http.post(`policies/${policyId}/actions/sync`, payload)
  }

}

// mutations
const mutations = {
  SET_ACTIONS (state, value) {
    state.actions = value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
