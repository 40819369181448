<template>
  <transition
    enter-active-class="transition ease-in-out duration-300 transform"
    enter-class="-translate-x-full"
    enter-to-class="translate-x-0"
    leave-active-class="transition ease-in-out duration-300 transform"
    leave-class="translate-x-0"
    leave-to-class="-translate-x-full"
  >
    <div class="hidden md:flex md:flex-shrink-0">
      <div
        v-if="full"
        class="flex flex-col w-64 sidebar-width"
      >
        <div class="flex flex-col h-0 flex-1 bg-primary">
          <div class="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
            <div class="flex items-center flex-shrink-0 px-4">
              <logo />
            </div>
            <navigation class="mt-5 flex-1 px-2 bg-primary space-y-1" />
          </div>
          <navigation-avatar />
        </div>
      </div>
      <div
        v-else
        class="flex flex-col w-28 sidebar-width"
      >
        <div class="flex flex-col h-0 flex-1 bg-primary">
          <div class="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
            <div class="flex items-center justify-center flex-shrink-0 px-4">
              <logo minimal />
            </div>
            <navigation
              short
              class="mt-3 flex-1 px-4 bg-primary space-y-1"
            />
          </div>
          <navigation-avatar
            short
            :name="false"
          />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Logo from '@/components/layout/header/logo.vue'
import Navigation from '@/components/layout/navigation/list.vue'
import NavigationAvatar from '@/components/layout/navigation/avatar.vue'
export default {
  components: {
    Logo,
    Navigation,
    NavigationAvatar
  },
  props: {
    full: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss" scoped>
.sidebar-width {
  transition: width 0.25s;
}
</style>
